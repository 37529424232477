import { navigate } from '@reach/router';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC, useState, useEffect } from 'react';
import { toFormikErrors } from 'utils/errorhelper';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Loading from 'components/old/Loading';
import { CustomResponse } from 'hooks/api';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { CompleteInvitationModel, InvitationModel } from 'hooks/invitation';

interface InvitationRegisterTemplateProps {
    code: string;
    completeRegistrationAction: (completeRegistrationAction: CompleteInvitationModel) => Promise<CustomResponse<unknown>>;
    invitationInfoAction: (code: string) => Promise<CustomResponse<InvitationModel>>;
    acceptInvitationAction: (code: string) => Promise<CustomResponse<unknown>>;
    denyInvitationAction: (code: string) => Promise<CustomResponse<unknown>>;
}

const InvitationRegisterTemplate: FC<InvitationRegisterTemplateProps> = ({ code, completeRegistrationAction, invitationInfoAction, acceptInvitationAction, denyInvitationAction }) => {
    const { t } = useTranslation('employeeRegisterTemplate');
    const [isInvitationInfoLoading, setIsInvitationInfoLoading] = useState(true);
    const [invitationInfo] = useState<InvitationModel>();

    const initialValues: CompleteInvitationModel = {
        firstName: '',
        insertion: undefined,
        lastName: '',
        password: '',
        confirmPassword: '',
        code: ''
    };

    const goToLogin = () => {
        navigate('/account/login');
    };

    const fetchInvitationInfo = async () => {
        setIsInvitationInfoLoading(true);
        await invitationInfoAction(code);
        setIsInvitationInfoLoading(false);
    };

    useEffect(() => {
        fetchInvitationInfo();
    }, []);

    const handleSubmit = async (values: CompleteInvitationModel, actions: FormikHelpers<CompleteInvitationModel>) => {
        actions.setSubmitting(true);
        values.code = code;
        const response = await completeRegistrationAction(values);

        if (!response.ok && response.errors) {
            const errors = toFormikErrors<CompleteInvitationModel>(response.errors);
            actions.setErrors(errors);

        } else if (!response.ok && !response.errors) {
            toast.error(t('generalError'));
        }

        if (response.ok) {
            toast.success(t('generalSuccess'));
            goToLogin();
        }
        actions.setSubmitting(false);
    };

    if (isInvitationInfoLoading) {
        return <Loading />;
    }

    return (
        invitationInfo?.isAlreadyKnown === true
            ? (
                <>
                    {goToLogin}
                </>
            )
            :
            (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Form>
                                <>
                                    <p>
                                        {t('message')}
                                    </p>
                                    <FormGroup>
                                        <FormikFormControl
                                            required
                                            name="firstName"
                                            placeholder={t('inputs.firstName')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikFormControl
                                            name="insertion"
                                            placeholder={t('inputs.insertion')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikFormControl
                                            required
                                            name="lastName"
                                            placeholder={t('inputs.lastName')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikFormControl
                                            required
                                            type="password"
                                            name="password"
                                            placeholder={t('inputs.password')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikFormControl
                                            required
                                            type="password"
                                            name="passwordConfirm"
                                            placeholder={t('inputs.passwordConfirm')}
                                        />
                                    </FormGroup>
                                    <Group right>
                                        <Button type="button" onClick={goToLogin} white>
                                            {t('cancelButton')}
                                        </Button>
                                        <Button type="submit">
                                            {t('submitButton')}
                                        </Button>
                                    </Group>
                                </>
                            </Form>
                        </Form>
                    )}
                </Formik>
            )
    );
};

export default InvitationRegisterTemplate;