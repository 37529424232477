import React, { FC } from 'react';
import { Line, Key, Value } from './styles';

export interface KeyValueLineProps {
    title: string;
    value?: string;
    last?: boolean;
    highlightValue?: boolean;
    small?: boolean;
}

const KeyValueLine: FC<KeyValueLineProps> = ({ title, highlightValue, value, last, small }) => {
    return (
        <Line last={last}>
            <Key small={small}>{title}</Key>
            {value && <Value small={small} highlightValue={highlightValue}>{value}</Value>}
        </Line>
    );
};

export default KeyValueLine;
