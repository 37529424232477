import styled from '@emotion/styled';
import { Brand } from 'static/theme/brand';

interface NotificationProps {
    color?: keyof Brand;
    disabled?: boolean;
}

const Notification = styled.div<NotificationProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 0.75rem;
    font-size: 0.75rem;
    font-weight: 400;
    background-color: ${({ theme, color, disabled }) => disabled ? theme.colors.border : theme.brand[color ?? 'pink']};
    color: ${({ theme, color, disabled }) => disabled ? theme.colors.dark : theme.colors.white};
    padding: 0 8px;
    margin-left: 8px;
`;

export default Notification;
