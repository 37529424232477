import Step, { StepProps } from 'components/atoms/Step';
import React, { FC } from 'react';
import { Visible } from 'react-grid-system';
import { Stepper as Wrapper } from './styles';

export interface StepperProps {
    steps: StepProps[];
}

const Stepper: FC<StepperProps> = ({ steps }) => {
    return (
        <Wrapper>
            {steps.map((step, index) => (
                <Visible key={step.step} xs={step.active} sm={step.active} md lg xl xxl>
                    <Step {...step} />
                </Visible>
            ))}
        </Wrapper>
    );
};

export default Stepper;
