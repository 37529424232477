import { ActiveEnvironmentEnum } from 'contexts/sessionContext';
import { Role } from 'utils/constants';

export const defaultAreaRoute = (role: Role, activeEnvironment: ActiveEnvironmentEnum) => {
    if (role === Role.Admin) {
        switch (activeEnvironment) {
            case ActiveEnvironmentEnum.Admin:
                return '/admin';
            case ActiveEnvironmentEnum.Gym:
                return '/';
            case ActiveEnvironmentEnum.Customer:
                // TODO: navigate to proper area?
                return '/';
            case ActiveEnvironmentEnum.Employer:
                return '/employer';
            case ActiveEnvironmentEnum.Location:
                return '/location';
            case ActiveEnvironmentEnum.Association:
                return '/association';
            case ActiveEnvironmentEnum.TipsAndTricks:
                return '/tipsAndTricks';
            case ActiveEnvironmentEnum.Content:
                return '/content';
            case ActiveEnvironmentEnum.Supplier:
                return '/supplier';
        }
    } else if (role === Role.ContentCreator) {
        switch (activeEnvironment) {
            case ActiveEnvironmentEnum.TipsAndTricks:
            default:
                return '/tipsAndTricks';
        }
    } else if (role === Role.Employer) {
        switch (activeEnvironment) {
            case ActiveEnvironmentEnum.Employer:
            default:
                return '/employer';
        }
    } else if (role === Role.Supplier) {
        switch (activeEnvironment) {
            case ActiveEnvironmentEnum.Supplier:
            default:
                return '/supplier';
        }
    } else {
        switch (activeEnvironment) {
            case ActiveEnvironmentEnum.Gym:
                return '/';
            case ActiveEnvironmentEnum.Customer:
                // TODO: navigate to proper area?
                return '/';
            case ActiveEnvironmentEnum.Location:
                return '/location';
            case ActiveEnvironmentEnum.Employer:
                return '/employer';
            case ActiveEnvironmentEnum.TipsAndTricks:
                return '/tipsAndTricks';
            case ActiveEnvironmentEnum.Content:
                return '/content';
            case ActiveEnvironmentEnum.Supplier:
                return '/supplier';
            default:
                return '/';
        }
    }
};
