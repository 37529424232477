import React, { FC } from 'react';
import { Size } from 'static/theme';
import { Group as Wrapper } from './styles';

export interface GroupProps {
    spaceBetween?: boolean;
    right?: boolean;
    stretch?: boolean;
    size?: keyof Size;
    className?: string;
}

const Group: FC<GroupProps> = ({ children, spaceBetween, right, className, size }) => {
    return (
        <Wrapper spaceBetween={spaceBetween} right={right} className={className} size={size}>
            {children}
        </Wrapper>
    );
};

export default Group;
