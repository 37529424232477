import { useField } from 'formik';
import React, { FC } from 'react';
import FormCurrencyInput from '..';

interface FormikFormControlProps {
    name: string;
    defaultValue: number;
}

const FormikFormCurrency: FC<FormikFormControlProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);

    // Methods.
    const onPriceChange = (e: any, fieldValue?: string) => {
        if (fieldValue === undefined) return;
        const toNumber = Number(fieldValue.replace(',', '.'));

        if (isNaN(toNumber)) {
            return;
        }
        helpers.setValue(toNumber);
        helpers.setTouched(true);
    };

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormCurrencyInput disableGroupSeparator={false} {...field} {...props} onChange={onPriceChange} error={error} />;
};

export default FormikFormCurrency;