import React, { FC, ReactNode } from 'react';
import { FormGroup as Wrapper, Label, Header, Text } from './styles';
import Tooltip from 'components/atoms/Tooltip';

interface FormGroupProps {
    label?: string;
    horizontal?: boolean;
    border?: boolean;
    required?: boolean;
    text?: string | ReactNode;
    noMargin?: boolean;
    tooltip?: string;
    isLarge?: boolean;
    isLight?: boolean;
}

const FormGroup: FC<FormGroupProps> = ({ label, horizontal, border, text, children, required, noMargin, tooltip, isLarge, isLight }) => {
    return (
        <Wrapper border={border} horizontal={horizontal} noMargin={noMargin}>
            {(label || text) && (
                <Header horizontal={horizontal}>
                    {label && (
                        <Label isLarge={isLarge} isLight={isLight} required={required}>
                            {label}
                        </Label>
                    )}
                    {text && <Text>{text}</Text>}
                    {tooltip && <Tooltip>{tooltip}</Tooltip>}
                </Header>
            )}
            <div>{children}</div>
        </Wrapper>
    );
};

export default FormGroup;

