import React from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import theme from 'static/theme';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import { Button } from 'components/atoms/button';
import { useTranslation } from 'react-i18next';
import Margin from 'components/atoms/Margin';

interface EmailInputProps {
    onSubmit: (value: string, helpers: FormikHelpers<EmailInputValues>) => void;
    baseValue?: string;
}

export interface EmailInputValues {
    email: string;
}

const useEmailInputModal = (emailInputProps: EmailInputProps): [() => void, () => void, boolean] => {
    const { t } = useTranslation('emailModal');
    const initialValues: EmailInputValues = { email: emailInputProps.baseValue ?? '' };

    const handleSubmit = async (values: EmailInputValues, helpers: FormikHelpers<EmailInputValues>) => {
        emailInputProps.onSubmit(values.email, helpers);
    };

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal onHide={hide} inProp={inProp} onExited={onExited} title={t('title')} size="large">
                <div style={{ marginTop: -theme.card.padding.horizontal }}>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        <Form>
                            <Margin top={1}>
                                <FormGroup label={t('email')}>
                                    <FormikFormControl name="email" placeholder={t('email')} />
                                </FormGroup>
                            </Margin>
                            <Button type="submit"> {t('title')} </Button>
                        </Form>
                    </Formik>
                </div>
            </Modal>
        ),
        []
    );

    return [show, hide, isShowing];
};
export default useEmailInputModal;
