import { navigate, RouteComponentProps } from '@reach/router';
import { useSession } from 'contexts/sessionContext';
import { useUser } from 'hooks/user';
import React, { FC, useEffect } from 'react';


interface SettingsProps extends RouteComponentProps {
    employerId?: string;
}

const SettingsRedirect: FC<SettingsProps> = ({ employerId }) => {
    const { session } = useSession();
    const { switchActiveEmployer } = useUser();

    const handleRedirecting = async () => {

        if (employerId !== undefined && session !== undefined) {
            await switchActiveEmployer(employerId);
            navigate('/employer/settings');
        }
    };

    useEffect(() => {
        handleRedirecting();
    }, []);

    return (<></>);
};

export default SettingsRedirect;