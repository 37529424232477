import React, { FC } from 'react';
import { FieldArray, Form, useFormikContext } from 'formik';
import { FormDropFile, FormGroup, FormikFormControl, FormikFormSelect, FormikFormTextarea } from 'components/atoms/form';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { AddFitnessProductModel, BtwPercentageType, FitnessProductFileModel, ProductDuration, ProductSalesUnitType } from 'hooks/fitnessProducts';
import FormikFormCurrency from 'components/atoms/form/FormCurrency/formik';
import { cdnFileUrl } from 'utils/cdnHelper';
import FormikFormSingleError from 'components/atoms/form/FormSingleError/formik';
import FileSingle from 'components/atoms/File';
import { Section } from 'components/atoms/text';

const imageInitialValues: FitnessProductFileModel = {
    id: undefined,
    image: undefined,
    imageHash: undefined,
    order: 0
};

export interface SupplierProductFormProps {
    mode: 'add' | 'edit';
    onCancel?: () => void;
}

const AddSupplierProductForm: FC<SupplierProductFormProps> = ({ mode, onCancel }) => {
    const { t } = useTranslation('templateFitnessProducts');
    const { values, setFieldValue } = useFormikContext<AddFitnessProductModel>();

    const handleAddImage = async (file: File) => {
        if (values.images === undefined) return;

        const newImage = { ...imageInitialValues, image: file };
        const images = values.images;
        images.push(newImage);
        setFieldValue('images', images);
    };

    const productDurationTypes = Object.keys(ProductDuration).filter(k => typeof ProductDuration[k as any] === 'number')
        .map(k => ({ title: t('ProductDuration.' + k), value: ProductDuration[k as any] }));

    const productSalesUnitTypes = Object.keys(ProductSalesUnitType).filter(k => typeof ProductSalesUnitType[k as any] === 'number')
        .map(k => ({ title: t('ProductSalesUnitType.' + k), value: ProductSalesUnitType[k as any] }));

    const btwPercentageTypes = Object.keys(BtwPercentageType).filter(k => typeof BtwPercentageType[k as any] === 'number')
        .map(k => ({ title: t('BtwPercentageType.' + k), value: BtwPercentageType[k as any] }));

    return (
        <>
            <Form>
                <FormGroup>
                    <FieldArray name="images">
                        {(arrayhelpers) => (
                            <div>
                                <FormDropFile
                                    label={t('form.label.image')}
                                    onChange={(value: File) => handleAddImage(value)}
                                />
                                <FormikFormSingleError name="images" />
                                {
                                    values.images !== undefined && values.images.map((image, index) => (
                                        <>
                                            <FileSingle key={index}
                                                image={image.image !== undefined && image.image !== null
                                                    ? URL.createObjectURL(image.image)
                                                    : (image.imageHash
                                                        ? cdnFileUrl(image.imageHash, null)
                                                        : undefined)
                                                }
                                                name={`${image.image?.name ?? image.fileName}`}
                                                size={image.image?.size && image.image.size}
                                                type={image.image?.type === 'image/jpeg' ? 'img' : 'doc'}
                                                onDeleteHandler={() => arrayhelpers.remove(index)}
                                            />
                                        </>
                                    ))
                                }
                            </div>
                        )}
                    </FieldArray>
                </FormGroup>
                <Section number={1}>{t('form.sections.product')}</Section>
                <Row>
                    <Col>
                        <FormGroup label={t('form.label.name')} required>
                            <FormikFormControl name="name" placeholder={t('form.placeholder.name')} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup label={t('form.label.typeNumber')} required>
                            <FormikFormControl name="numberType" placeholder={t('form.placeholder.typeNumber')} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup label={t('form.label.price')} required>
                            <FormikFormCurrency
                                name="purchasePrice"
                                defaultValue={values.purchasePrice}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup label={t('form.label.btwPercentage')}>
                            <FormikFormSelect<string>
                                name={'btwPercentage'}
                                options={btwPercentageTypes}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup label={t('form.label.salesUnit')}>
                            <FormikFormSelect<string>
                                name={'productSalesUnit'}
                                options={productSalesUnitTypes}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup label={t('form.label.duration')}>
                            <FormikFormSelect<string>
                                name={'productDuration'}
                                options={productDurationTypes}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup label={t('form.label.description')} required>
                    <FormikFormTextarea
                        name="description"
                        placeholder={t('form.placeholder.description')}
                    />
                </FormGroup>
            </Form>
        </>
    );
};

export default AddSupplierProductForm;

