// @ts-nocheck not sure how to fix the StyledComponent types
import React from 'react';
import { CellProps, Hooks } from 'react-table';
import { FormCheck } from 'components/atoms/form';

// eslint-disable-next-line @typescript-eslint/ban-types
const useRowSelection = <T extends object>(isRowSelect?: boolean) => {
    return (hooks: Hooks<T>) => {
        if (!isRowSelect) {
            return;
        }

        hooks.visibleColumns.push((columns) => [
            {
                id: 'selection',
                Header: ({ getToggleAllPageRowsSelectedProps }) => <FormCheck {...getToggleAllPageRowsSelectedProps()} />,
                Cell: ({ row }: CellProps<T>) => <FormCheck {...row.getToggleRowSelectedProps()} />
            },
            ...columns
        ]);
    };
};

export default useRowSelection;
