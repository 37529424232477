import React, { FC, useState, useEffect } from 'react';
import Dropdown from '../Dropdown';
import { useUser } from 'hooks/user';
import { ActiveEnvironmentEnum, useSession } from 'contexts/sessionContext';
import { defaultAreaRoute } from 'utils/areaHelper';
import { navigate } from '@reach/router';
import { LocationPlainModel, useLocations } from 'hooks/locations';
import { DropdownItemProps } from 'components/atoms/item/DropdownItem';

export interface LocationDropdownProps {
    environment: ActiveEnvironmentEnum;
}

const LocationDropdown: FC<LocationDropdownProps> = ({ environment }) => {
    const [locations, setLocations] = useState<LocationPlainModel[]>([]);
    const { session } = useSession();
    const { switchActiveLocation, switchActiveGym, switchActiveEnvironment } = useUser();
    const { allLocations } = useLocations();

    // Life cycle
    useEffect(() => {
        getLocations();
    }, [session?.activeGymId]);

    // Methods
    const handleOnSubItemClick = async (value: string) => {
        const response = await switchActiveLocation(value);

        if (response.ok && session?.role && session.activeEnvironment) {
            if (environment === ActiveEnvironmentEnum.Gym) {
                switchActiveEnvironment(ActiveEnvironmentEnum.Location);
            }

            const route = defaultAreaRoute(session.role, session.activeEnvironment);
            navigate(route);
        } else {
            // TODO: set prev value or something.
        }
    };

    const handleOnGymClick = async () => {
        if (!session?.activeGymId) {
            return;
        }

        const response = await switchActiveGym(session.activeGymId);

        if (response.ok && session?.role && session.activeEnvironment) {
            if (environment === ActiveEnvironmentEnum.Location) {
                switchActiveEnvironment(ActiveEnvironmentEnum.Gym);
            }

            const route = defaultAreaRoute(session.role, session.activeEnvironment);
            navigate(route);
        } else {
            // TODO: set prev value or something.
        }
    };

    const getLocations = async () => {
        const response = await allLocations({
            employeeId: session?.userId,
            gymId: session?.activeGymId
        });

        if (response.ok && response.data) {
            setLocations(response.data);
        }
    };

    // Render.
    const activeLocationId = session?.activeLocationId;
    const items: DropdownItemProps[] = [
        {
            value: session?.activeGymId,
            // TODO translate
            title: 'Hoofdlocatie',
            onClick: handleOnGymClick
        },
        ...locations.map((location) => ({ value: location.id, title: location.name, onClick: () => handleOnSubItemClick(location.id) }))
    ];

    // TODO translate
    const title =
        environment === ActiveEnvironmentEnum.Location ? locations.find((item) => item.id === activeLocationId)?.name : 'Hoofdlocatie';

    return <Dropdown items={items}>{title}</Dropdown>;
};

export default LocationDropdown;
