import React, { FC } from 'react';
import { Title } from './styles';

interface SectionProps {
    number: number;
}

const Section: FC<SectionProps> = ({ number, children }) => {
    return (
        <Title>
            {number}. {children}
        </Title>
    );
};

export default Section;
