import React, { FC } from 'react';
import { css, Global } from '@emotion/react';
import InvalidFeedback from '../InvalidFeedback';
import { Wrapper, DateIcon } from './styles';
import { useTheme } from '@emotion/react';
import Icon from 'components/atoms/Icon';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export interface FormDateProps extends ReactDatePickerProps {
    error?: string;
}

const FormDate: FC<FormDateProps> = ({ error, ...props }) => {
    const theme = useTheme();

    const styles = css`
        .react-datepicker__input-container input {
            width: 100%;
            height: ${theme.form.height}rem;
            border: 1px solid ${theme.greys[50]};
            border-radius: ${theme.borderRadius.xl}px;
            padding: 0 ${theme.form.padding.horizontal}px;
            font-size: ${theme.form.fontSize}rem;
            padding-right: 3rem;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 0.2rem ${theme.greys[50]};
            }

            &:hover {
                border-color: ${theme.greys[100]};
            }

            ::placeholder {
                color: ${theme.greys[300]};
            }
        }
        .react-datepicker-popper {
            z-index: 6;
        }

    `;

    return (
        <>
            <>
                <Global styles={styles} />
                <Wrapper>
                    <DateIcon>
                        <Icon name="calendar" size={.85} color={theme.colors.dark} />
                    </DateIcon>
                    <DatePicker {...props} dateFormat="dd-MM-yyyy" showYearDropdown showMonthDropdown />
                </Wrapper>
            </>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormDate;
