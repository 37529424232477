import { Router } from '@reach/router';
import React, { lazy, Suspense } from 'react';
import 'style/style.scss';
import { useMenu } from 'hooks/menu';
import { ActiveEnvironmentEnum } from 'contexts/sessionContext';
import Points from 'components/atoms/Points';
import Loading from 'components/atoms/Loading';
import SettingsRedirect from 'screens/EmployerEnvironment/Settings/redirect';
import SupplierRegister from 'screens/AuthenticationEnvironment/SupplierRegister';

// Admin environment
const Gyms = lazy(() => import('screens/AdminEnvironment/Gyms'));
const AdminDashboard = lazy(() => import('screens/AdminEnvironment/AdminDashboard'));
const ManageAdmins = lazy(() => import('screens/AdminEnvironment/ManageAdmins'));
const ManageInvitedAdmins = lazy(() => import('screens/AdminEnvironment/ManageInvitedAdmins'));
const Employers = lazy(() => import('screens/AdminEnvironment/Employers'));
const AdminXpTransactionInvoices = lazy(() => import('screens/AdminEnvironment/xpTransactions/Invoices'));
const AdminXpTransactions = lazy(() => import('screens/AdminEnvironment/xpTransactions/Transactions'));
const Store = lazy(() => import('screens/AdminEnvironment/Store'));
const OverviewProducts = lazy(() => import('screens/SupplierEnvironment/FitnessProducts/index'));
const Associations = lazy(() => import('screens/AdminEnvironment/Associations'));
const UserOverview = lazy(() => import('screens/AdminEnvironment/UserOverview'));
const ContentEnvironments = lazy(() => import('screens/AdminEnvironment/ContentEnvironment'));
const ManagePictureBankItems = lazy(() => import('screens/AdminEnvironment/PictureBank'));
const SuppliersOverview = lazy(() => import('screens/AdminEnvironment/Suppliers'));
const CommissionDeterminedOverview = lazy(() => import('screens/AdminEnvironment/CommissionDeterminedProductsOverview'));
const ManageChallengeTemplates = lazy(() => import('screens/AdminEnvironment/ChallengeTemplate'));

// Authentication environment
const AdminRegister = lazy(() => import('screens/AuthenticationEnvironment/AdminRegister'));
const GymEmployeeRegister = lazy(() => import('screens/AuthenticationEnvironment/GymEmployeeRegister'));
const EmployerEmployeeRegister = lazy(() => import('screens/AuthenticationEnvironment/EmployerEmployeeRegister'));
const ContentCreatorRegister = lazy(() => import('screens/AuthenticationEnvironment/ContentCreatorRegister'));
const GymEmployerRegister = lazy(() => import('screens/AuthenticationEnvironment/GymEmployerRegister'));
const EmployerRegister = lazy(() => import('screens/AuthenticationEnvironment/GymEmployerRegister/Employer'));
const Login = lazy(() => import('screens/AuthenticationEnvironment/Login'));
const Tfa = lazy(() => import('screens/AuthenticationEnvironment/Tfa'));
const LoginWithUsername = lazy(() => import('screens/AuthenticationEnvironment/LoginWithUsername'));
const PasswordForgotten = lazy(() => import('screens/AuthenticationEnvironment/PasswordForgotten'));
const ResetPassword = lazy(() => import('screens/AuthenticationEnvironment/ResetPassword'));
const DeleteAccount = lazy(() => import('screens/AuthenticationEnvironment/DeleteAccount'));

// Gym environment
const Home = lazy(() => import('screens/GymEnvironment/Home'));
const ManageEmployees = lazy(() => import('screens/GymEnvironment/ManageEmployees'));
const ManageLocations = lazy(() => import('screens/GymEnvironment/ManageLocations'));
const EditGeneralLocationSettings = lazy(() => import('screens/GymEnvironment/editLocation/General'));
const EditPaymentProviderLocationSettings = lazy(() => import('screens/GymEnvironment/editLocation/PaymentProvider'));
const EditStatusRequirementsLocationSettings = lazy(() => import('screens/GymEnvironment/editLocation/StatusRequirements'));
const EditLocationEmployees = lazy(() => import('screens/GymEnvironment/editLocation/Employees'));
const ManageInvitedEmployees = lazy(() => import('screens/GymEnvironment/ManageInvitedEmployees'));
const ManageSubscriptions = lazy(() => import('screens/GymEnvironment/ManageSubscriptions'));
const ManageInactiveSubscriptions = lazy(() => import('screens/GymEnvironment/ManageInactiveSubscriptions'));
const EditSubscription = lazy(() => import('screens/GymEnvironment/EditSubscription'));
const ManageSubscriptionVariantLocations = lazy(() => import('screens/GymEnvironment/ManageSubscriptionVariantLocations'));
const ManageSubscriptionMembers = lazy(() => import('screens/GymEnvironment/ManageSubscriptionMembers'));
const ManageMembers = lazy(() => import('screens/GymEnvironment/ManageMembers'));
const ManageMembershipEntries = lazy(() => import('screens/GymEnvironment/ManageMembershipEntries'));
const MemberDetails = lazy(() => import('screens/GymEnvironment/MemberDetails'));
const EmployeeDetails = lazy(() => import('screens/GymEnvironment/EmployeeDetails'));
const ManageEmployeeRights = lazy(() => import('screens/GymEnvironment/ManageEmployeeRights'));
const ManageMemberInvoices = lazy(() => import('screens/GymEnvironment/ManageMemberInvoices'));
const ManageInvoices = lazy(() => import('screens/GymEnvironment/ManageInvoices'));
const GymSettings = lazy(() => import('screens/GymEnvironment/GymSettings'));
const GymRegistrationSettings = lazy(() => import('screens/GymEnvironment/GymRegistrationSettings'));
const GymRightGroupSettings = lazy(() => import('screens/GymEnvironment/GymRightGroupSettings'));
const GymRightGroupEmployeeRights = lazy(() => import('screens/GymEnvironment/GymRightGroups/EmployeeRights'));
const GymEmployerInvitations = lazy(() => import('screens/GymEnvironment/ManageEmployers'));

// Location environment
const LocationDashboard = lazy(() => import('screens/LocationEnvironment/LocationDashboard'));
const ManageInsideMembers = lazy(() => import('screens/LocationEnvironment/ManageInsideMembers'));
const ManageLocationRights = lazy(() => import('screens/LocationEnvironment/ManageLocationRights'));
const LocationGeneralSettings = lazy(() => import('screens/LocationEnvironment/locationSettings/General'));
const LocationPaymentProviderSettings = lazy(() => import('screens/LocationEnvironment/locationSettings/PaymentProvider'));
const LocationStatusRequirementsSettings = lazy(() => import('screens/LocationEnvironment/locationSettings/StatusRequirements'));
const LocationInvoices = lazy(() => import('screens/LocationEnvironment/LocationInvoices'));
const LocationLessons = lazy(() => import('screens/LocationEnvironment/LocationLessons'));
const LocationAreas = lazy(() => import('screens/LocationEnvironment/LocationAreas'));
const EditLocationArea = lazy(() => import('screens/LocationEnvironment/EditLocationArea'));
const ManageLocationEmployees = lazy(() => import('screens/LocationEnvironment/ManageLocationEmployees'));
const ManageLocationMembers = lazy(() => import('screens/LocationEnvironment/ManageLocationMembers'));
const LocationMemberDetails = lazy(() => import('screens/LocationEnvironment/LocationMemberDetails'));
const LocationMemberEntries = lazy(() => import('screens/LocationEnvironment/LocationMemberDetailsEntries'));
const LocationMemberInvoices = lazy(() => import('screens/LocationEnvironment/LocationMemberDetailsInvoices'));
const LocationEmployeeDetails = lazy(() => import('screens/LocationEnvironment/LocationEmployeeDetails'));
const ManageLocationEmployeeRights = lazy(() => import('screens/LocationEnvironment/ManageLocationEmployeeRights'));
const LocationReports = lazy(() => import('screens/LocationEnvironment/Reports'));
const LocationChallengeOverview = lazy(() => import('screens/LocationEnvironment/challenge/Overview'));
const LocationOpeningHoursSettings = lazy(() => import('screens/LocationEnvironment/locationSettings/OpeningHours'));
const Profile = lazy(() => import('screens/Profile'));
const LocationPackets = lazy(() => import('screens/LocationEnvironment/locationSettings/Packets'));
const LocationOneTimeCheckin = lazy(() => import('screens/LocationEnvironment/locationSettings/OneTimeCheckin'));
const LocationManageEmployers = lazy(() => import('screens/LocationEnvironment/ManageEmployers'));
const ManageProductOrders = lazy(() => import('screens/LocationEnvironment/ManageProductOrders'));

// Employer environment
const EmployerDashboard = lazy(() => import('screens/EmployerEnvironment/EmployerDashboard'));
const ManageEmployerEmployees = lazy(() => import('screens/EmployerEnvironment/ManageEmployees'));
const XpTransactionInvoices = lazy(() => import('screens/EmployerEnvironment/xpTransactions/XpInvoices'));
const XpTransactions = lazy(() => import('screens/EmployerEnvironment/xpTransactions/Transactions'));
const InvoicesOverview = lazy(() => import('screens/EmployerEnvironment/Invoices'));
const EmployerChallengeOverview = lazy(() => import('screens/EmployerEnvironment/challenge/Overview'));
const EmployerShop = lazy(() => import('screens/EmployerEnvironment/Shop'));
const EmployerOrderDetail = lazy(() => import('screens/EmployerEnvironment/OrderDetail'));
const Settings = lazy(() => import('screens/EmployerEnvironment/Settings'));

// Association environment
const AssociationDashboard = lazy(() => import('screens/AssociationEnvironment/AssociationDashboard'));
const ManageAssociationLocations = lazy(() => import('screens/AssociationEnvironment/Locations'));
const AssociationChallengeOverview = lazy(() => import('screens/AssociationEnvironment/Challenge'));

// Checkout environment
const Checkout = lazy(() => import('screens/CheckoutEnvironment/Checkout'));
const CheckoutComplete = lazy(() => import('screens/CheckoutEnvironment/CheckoutComplete'));

// Tips and tricks environment
const OverviewRecipe = lazy(() => import('screens/ContentCreatorEnvironment/Recipes/Overview/index'));
const ManageRecipe = lazy(() => import('screens/ContentCreatorEnvironment/Recipes/ManageRecipe/index'));
const OverviewBlog = lazy(() => import('screens/ContentCreatorEnvironment/NutritionBlogs/OverviewBlog/index'));
const ManageBlog = lazy(() => import('screens/ContentCreatorEnvironment/NutritionBlogs/ManageBlog/index'));

// Content environment
const ContentEnvironmentDashboard = lazy(() => import('screens/ContentEnvironment/ContentDashboard'));
const ManageContentCreators = lazy(() => import('screens/ContentEnvironment/ManageContentCreators'));
const EnvironmentOverviewRecipe = lazy(() => import('screens/ContentEnvironment/Recipe/OverviewRecipe'));
const EnvironmentOverviewBlog = lazy(() => import('screens/ContentEnvironment/Blog/OverviewBlog'));
const EnvironmentOverviewWorkout = lazy(() => import('screens/ContentEnvironment/Workout'));
const ManageContentRights = lazy(() => import('screens/ContentEnvironment/ManageContentRight'));

//Supplier environment
const SupplierDashboard = lazy(() => import('screens/SupplierEnvironment/SupplierDashboard'));
const ManageProducts = lazy(() => import('screens/SupplierEnvironment/FitnessProducts/Manage/index'));
const ManageSupplierProductOrders = lazy(() => import('screens/SupplierEnvironment/ProductOrders/index'));

// Layouts
const Default = lazy(() => import('layouts/Default')); // TODO remove lazy here. a template/layout is not heavy to load. wrap suspence around his children.
const LoginLayout = lazy(() => import('layouts/Login'));
const CheckoutLayout = lazy(() => import('layouts/Checkout'));

const App = () => {
    const { gymMenuItems, employerMenuItems, locationMenuItems, adminMenuItems, associationMenuItems, tipsAndTricksMenuItems, ContentMenuItems, supplierItems } = useMenu();

    return (
        <Suspense fallback={<Loading isFull />}>
            <Router>
                <LoginLayout path="account">
                    <AdminRegister path="register/admin/:code" />
                    <GymEmployeeRegister path="register/gym/employee/:code" />
                    <EmployerEmployeeRegister path="register/employer/employee/:code" />
                    <ContentCreatorRegister path="register/contentCreator/:code" />
                    <GymEmployerRegister path="register/gym/employer/:code" />
                    <EmployerRegister path="register/employer/:code" />
                    <SupplierRegister path="register/supplier/:code" />
                    <Login path="login" />
                    <Login path="login/:redirectUrl" />
                    {process.env.NODE_ENV === 'development' && <LoginWithUsername path="loginwithusername/:username" />}
                    <Tfa path="tfa" />
                    <Tfa path="tfa/:redirectUrl" />
                    <PasswordForgotten path="password-forgotten" />
                    <PasswordForgotten path="password-forgotten-confirm" step={2} />
                    <ResetPassword path="reset-password" />
                    <DeleteAccount path="delete-account" />
                </LoginLayout>
                <Default items={gymMenuItems} path="/" environment={ActiveEnvironmentEnum.Gym}>
                    <Home path="/" default />
                    <Profile path="profile" />
                    <ManageEmployees path="employees" />
                    <EmployeeDetails path="employees/:employeeId" />
                    <ManageEmployeeRights path="employees/:employeeId/rights" />
                    <ManageLocations path="locations" />
                    <EditGeneralLocationSettings path="locations/:locationId/general" />
                    <EditPaymentProviderLocationSettings path="locations/:locationId/paymentProvider" />
                    <EditStatusRequirementsLocationSettings path="locations/:locationId/statusRequirements" />
                    <EditLocationEmployees path="locations/:locationId/employees" />
                    <GymEmployerInvitations path="employers" />
                    <ManageInvitedEmployees path="employees/invited" />
                    <ManageSubscriptions path="subscriptions" />
                    <ManageInactiveSubscriptions path="subscriptions/inactive" />
                    <EditSubscription path="subscriptions/:subscriptionId" />
                    <ManageSubscriptionVariantLocations path="subscriptions/:subscriptionId/locations" />
                    <ManageSubscriptionMembers path="subscriptions/:subscriptionId/members" />
                    <ManageMembers path="members" />
                    <MemberDetails path="members/:memberId" />
                    <ManageMembershipEntries path="members/:memberId/entries" />
                    <ManageMemberInvoices path="members/:memberId/invoices" />
                    <ManageInvoices path="invoices" />
                    <GymSettings path="settings" />
                    <GymRegistrationSettings path="settings/registration" />
                    <GymRightGroupSettings path="settings/rightGroups" />
                    <GymRightGroupEmployeeRights path="settings/rightGroups/:gymRightsGroupId/employees" />
                </Default>
                <Default items={locationMenuItems} path="/location" environment={ActiveEnvironmentEnum.Location}>
                    <LocationDashboard path="/" default />
                    <ManageLocationEmployees path="employees" />
                    <LocationEmployeeDetails path="employees/:employeeId" />
                    <ManageLocationEmployeeRights path="employees/:employeeId/rights" />
                    <ManageInsideMembers path="insideMembers" />
                    <ManageLocationMembers path="members" />
                    <LocationManageEmployers path="employers" />
                    <ManageLocationRights path="rights" />
                    <Profile path="profile" />
                    <LocationGeneralSettings path="settings/general" />
                    <LocationPaymentProviderSettings path="settings/paymentProvider" />
                    <LocationStatusRequirementsSettings path="settings/statusRequirements" />
                    <LocationOpeningHoursSettings path="settings/openingHours" />
                    <LocationPackets path="settings/packets" />
                    <LocationOneTimeCheckin path="settings/oneTimeCheckins" />
                    <LocationInvoices path="invoices" />
                    <LocationLessons path="lessons" />
                    <LocationAreas path="areas" />
                    <EditLocationArea path="areas/:locationAreaId" />
                    <LocationMemberDetails path="members/:memberId" />
                    <LocationMemberEntries path="members/:memberId/entries" />
                    <LocationMemberInvoices path="members/:memberId/invoices" />
                    <LocationReports path="reports" />
                    <LocationChallengeOverview path="challenges" />
                    <ManageProductOrders path="productOrders" />
                </Default>
                <Default
                    items={employerMenuItems}
                    path="/employer"
                    environment={ActiveEnvironmentEnum.Employer}
                    headerCotent={<Points />}
                >
                    <EmployerDashboard default />
                    <ManageEmployerEmployees path="employees" />
                    <XpTransactions path="xptransactions" />
                    <XpTransactionInvoices path="xptransactions/invoices" />
                    <XpTransactionInvoices path="xptransactions/invoices/:openInvoiceId" />
                    <InvoicesOverview path="invoices" />
                    <EmployerChallengeOverview path="challenges" />
                    <EmployerShop path="shop" />
                    <EmployerOrderDetail path="shop/order" />
                    <Settings path="settings" />
                    <SettingsRedirect path="settings/:employerId" />
                </Default>
                <Default items={adminMenuItems} path="/admin" environment={ActiveEnvironmentEnum.Admin}>
                    <AdminDashboard path="/" default />
                    <Profile path="profile" />
                    <ManageAdmins path="admins" />
                    <ManageInvitedAdmins path="admins/invited" />
                    <Associations path="associations" />
                    <Gyms path="gyms" />
                    <Employers path="employers" />
                    <AdminXpTransactions path="xptransactions" />
                    <AdminXpTransactionInvoices path="xptransactions/invoices" />
                    <Store path="store" />
                    <ManageChallengeTemplates path="challengeTemplate" />
                    <UserOverview path="userOverview" />
                    <ContentEnvironments path="contentEnvironment" />
                    <ManagePictureBankItems path="pictureBank" />
                    <SuppliersOverview path="suppliersOverview" />
                    <CommissionDeterminedOverview path="commissionDeterminedOverview" />
                </Default>
                <Default items={associationMenuItems} path="/association" environment={ActiveEnvironmentEnum.Association}>
                    <AssociationDashboard path="dashboard" />
                    <ManageAssociationLocations path="locations" />
                    <AssociationChallengeOverview path="challenges" />
                </Default>
                <Default items={tipsAndTricksMenuItems} path="/tipsAndTricks" environment={ActiveEnvironmentEnum.TipsAndTricks}>
                    <OverviewRecipe path="recipes" />
                    <ManageRecipe path="recipes/manage" />
                    <ManageRecipe path="recipes/manage/:recipeId" />
                    <OverviewBlog path="blogs" />
                    <ManageBlog path="blogs/manage" />
                    <ManageBlog path="blogs/manage/:blogId" />
                </Default>
                <Default items={ContentMenuItems} path="/content" environment={ActiveEnvironmentEnum.Content}>
                    <ContentEnvironmentDashboard default />
                    <ManageContentCreators path="creators" />
                    <EnvironmentOverviewRecipe path="recipes" />
                    <ManageRecipe path="recipes/manage" />
                    <ManageRecipe path="recipes/manage/:recipeId" />
                    <EnvironmentOverviewWorkout path="workout" />
                    <EnvironmentOverviewBlog path="blogs" />
                    <ManageBlog path="blogs/manage" />
                    <ManageBlog path="blogs/manage/:blogId" />
                    <ManageContentRights path="settings" />
                </Default>
                <Default items={supplierItems} path="/supplier" environment={ActiveEnvironmentEnum.Supplier}>
                    <SupplierDashboard default />
                    <ManageProducts path="products/manage" />
                    <ManageProducts path="products/manage/:id" />
                    <OverviewProducts path="products" />
                    <ManageSupplierProductOrders path="productOrders" />
                </Default>
                <CheckoutLayout path="inschrijven">
                    <Checkout default />
                    <Checkout path=":gymSlug" />
                    <Checkout path=":gymSlug/:locationSlug" />
                    <Checkout path=":gymSlug/:locationSlug/kiosk" kioskMode />
                    <CheckoutComplete path="afronden/:publicCode" />
                    <CheckoutComplete path="afronden/:publicCode/kiosk" kioskMode />
                </CheckoutLayout>
            </Router>
        </Suspense>
    );
};

export default App;
