import React, { FC } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import Icon from 'components/atoms/Icon';
import Dropzone from 'react-dropzone';
import { Wrapper } from './styles';

export interface FormDropFileProps {
    error?: string;
    value?: File;
    onChange?: (value: File) => void;
    label?: string;
    image?: string;
    showName?: boolean;
    onAfterChange?: (value: File) => void;
}

const FormDropFile: FC<FormDropFileProps> = ({ error, label, onChange, value, image, showName, onAfterChange }) => {
    const handleOnDrop = (files: File[]) => {
        if (onChange && files.length > 0) {
            onChange(files[0]);
            onAfterChange && onAfterChange(files[0]);
        }
    };

    return (
        <>
            <Dropzone onDrop={handleOnDrop} maxFiles={1}>
                {({ getRootProps, getInputProps }) => (
                    <Wrapper {...getRootProps()} background={image}>
                        <input {...getInputProps()} />
                        {
                            showName ?? (
                                value ? (
                                    <>
                                        <Icon name="edit" size={2} />
                                        <p>{value.name}</p>
                                    </>
                                ) : (
                                    <>
                                        <Icon name="add-file" size={2} />
                                        <p>{label}</p>
                                    </>
                                )
                            )
                        }
                    </Wrapper>
                )}
            </Dropzone>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormDropFile;
