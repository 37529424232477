import React, { FC, useEffect, useState } from 'react';
import { RoundButton } from 'components/atoms/button';
import { usePointsModal } from 'hooks/modals';
import { Points as Wrapper, Text } from './styles';
import Icon from 'components/atoms/Icon';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import { useEmployerTransactions } from 'hooks/employerTransactions';
import { useSession } from 'contexts/sessionContext';

numeral.locale('nl-nl');

export interface PointsProps {
}

const Points: FC<PointsProps> = () => {
    // Replace for the right hook.
    const { session } = useSession();
    const [points, setPoints] = useState(0);
    const [show] = usePointsModal({ onSubmit: (amount) => handleAddBalance(amount) });
    const { addBalance, getBalance } = useEmployerTransactions();

    const getPoints = async () => {
        const response = await getBalance(session?.activeEmployerId ?? '');
        if (response.ok && response.data) {
            setPoints(response.data);
        }
    };

    const handleAddBalance = async (amount: number) => {
        const response = await addBalance(amount, session?.activeEmployerId ?? '');
        if (response.ok) {
            getPoints();
        }
    };

    useEffect(() => {
        getPoints();
    }, []);

    return (
        <Wrapper>
            <Icon name="points" />
            <Text>{numeral(points).format('0,0')}xp</Text>
            <RoundButton icon="plus" onClick={() => show(points)} />
        </Wrapper>
    );
};

export default Points;
