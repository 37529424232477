import { RouteComponentProps } from '@reach/router';
import { useSuppliers } from 'hooks/suppliers';
import React, { FC } from 'react';
import InvitationRegisterTemplate from 'templates/invitationRegister';

interface SupplierRegisterProps extends RouteComponentProps {
    code?: string;
}

const SupplierRegister: FC<SupplierRegisterProps> = ({ code = '' }) => {
    const { invitationSupplierInfo, completeInvitationSupplier, acceptSupplier, denySupplier } = useSuppliers();

    return (
        <InvitationRegisterTemplate
            code={code}
            completeRegistrationAction={completeInvitationSupplier}
            invitationInfoAction={invitationSupplierInfo}
            acceptInvitationAction={acceptSupplier}
            denyInvitationAction={denySupplier}
        />
    );
};

export default SupplierRegister;