export function appendObject(object: any, formData?: FormData, prefix?: string) {
    prefix = prefix ?? '';
    formData = formData ?? new FormData();
    for (const key in object) {
        if (object[key] !== undefined) {
            const keyString = prefix + key;
            switch (typeof (object[key])) {
                case 'number':
                    formData.append(keyString, object[key].toString());
                    break;
                case 'string':
                    formData.append(keyString, object[key]);
                    break;
                case 'boolean':
                    formData.append(keyString, object[key]);
                    break;
                case 'object':
                    break;
                default:
                    formData.append(keyString, object[key]);
                    break;
            }
        }
    }
    return formData;
}

export function appendObjectArray(objectsArray: any[], formData?: FormData, prefix?: string) {
    objectsArray.forEach((item, index) => {
        appendObject(item, formData, `${prefix}[${index}].`);
    });
}

export function appendObjectWithImagesArray(objectsArray: any[], formData: FormData, prefix?: string) {
    objectsArray.forEach((item, index) => {
        appendObject(item, formData, `${prefix}[${index}].`);
        formData.append(`${prefix}[${index}].image`, item.image);
    });
}