import React, { FC, useMemo } from 'react';
import { H2 as Text } from './styles';
import { Colors } from 'static/theme/colors';
import { useTheme } from '@emotion/react';

export interface H2Props {
    variant?: keyof Colors;
    grey?: number;
    center?: boolean;
    className?: string;
    noMargin?: boolean;
}

const H2: FC<H2Props> = ({ children, variant, grey, center, noMargin, className }) => {
    const theme = useTheme();
    const color = useMemo(() => (variant ? theme.colors[variant] : grey ? theme.greys[grey] : theme.colors.dark), [variant, grey]);

    return (
        <Text color={color} center={center} className={className} noMargin={noMargin}>
            {children}
        </Text>
    );
};

export default H2;

