import { useApi } from 'hooks/api';
import { appendObject } from 'utils/objectAppender';
import { buildUriWithParams } from 'utils/uriHelper';

export enum PictureBankTagType {
    Movement = 0,
    Nutrition = 1,
    Relaxation = 2
}

export interface AddPictureBankItemPlainInputModel {
    id: string;
}

export interface AddPictureBankInputModel {
    picture: File;
    isActive: boolean;
    pictureBankTagType: PictureBankTagType;
}

export interface EditPictureBankInputModel {
    id?: string;
    picture?: File;
    isActive: boolean;
    pictureBankTagType: PictureBankTagType;
    pictureHash?: string;
}

export interface PictureBankValues {
    picture?: File;
    isActive: boolean;
    filename?: string;
    pictureBankTagType: PictureBankTagType;
    pictureHash?: string;
}

export interface PictureBankInputModel {
    id: string;
    isActive: boolean;
    filename: string;
    pictureBankTagType: PictureBankTagType;
    pictureHash: string;
}

export interface DeletePictureBankInputModel {
    id: string;
}

export interface pictureBankItemQueryOptions {
    isActive?: boolean;
    pictureBankTagType?: PictureBankTagType;
}

export const usePictureBank = () => {
    const { callApi } = useApi();

    const getAllPictureBankItems = async (queryOptions?: pictureBankItemQueryOptions) => {
        const uri = buildUriWithParams('/PictureBankItem/all', queryOptions);

        const response = await callApi.current<PictureBankInputModel[]>(uri);

        return response;
    };

    const getPictureBankById = async (id: string) => {
        const response = await callApi.current<PictureBankInputModel>(`/PictureBankItem/${id}`);
        return response;
    };

    const addPictureBankItem = async (model: AddPictureBankInputModel) => {

        const formData = new FormData();
        appendObject(model, formData);

        if (model.picture !== undefined) {
            formData.append('picture', model.picture);
        }

        const response = await callApi.current<AddPictureBankInputModel>('/PictureBankItem', {
            method: 'POST',
            body: formData
        });
        return response;
    };

    const editPictureBankItem = async (model: EditPictureBankInputModel) => {

        const formData = new FormData();
        appendObject(model, formData);

        if (model.picture !== undefined) {
            formData.append('picture', model.picture);
        }

        const response = await callApi.current<EditPictureBankInputModel>('/PictureBankItem', {
            method: 'PUT',
            body: formData
        });
        return response;
    };
 
    const deletePictureBankItem = async (id: string) => {
        const response = await callApi.current(`/PictureBankItem/${id}`,
            {
                method: 'DELETE'
            }
        );
        return response;
    };
   
    return {
        getAllPictureBankItems,
        getPictureBankById,
        addPictureBankItem,
        editPictureBankItem,
        deletePictureBankItem
    };
};