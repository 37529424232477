import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemProps } from 'components/atoms/MenuItem';
import { useSession } from 'contexts/sessionContext';
import { GymRight, LocationRight, Role } from 'utils/constants';

export const useMenu = () => {
    const { t } = useTranslation('menu');
    const { session, hasGymRight, hasRole, hasAccessToLocationRight } = useSession();

    // prettier-ignore
    const gymMenuItems: MenuItemProps[] = useMemo(() => {
        return [
            { title: t('gym.dashboard'), icon: 'dashboard', to: '/' },
            { title: t('gym.employees'), icon: 'employees', to: '/employees', hasRight: hasGymRight(GymRight.ManageMembers) },
            { title: t('gym.locations'), icon: 'marker', to: '/locations' },
            { title: t('gym.employers'), icon: 'employer', to: '/employers', hasRight: hasRole(Role.Admin) },
            { title: t('gym.subscriptions'), icon: 'memberships', to: '/subscriptions', hasRight: hasGymRight(GymRight.ManageSubscriptions) },
            { title: t('gym.members'), icon: 'members', to: '/members', hasRight: hasGymRight(GymRight.ManageMemberships) },
            { title: t('gym.payments'), icon: 'invoice', to: '/invoices', hasRight: hasGymRight(GymRight.ManageMemberships) },
            { title: t('gym.settings'), icon: 'settings', to: '/settings', hasRight: hasGymRight(GymRight.AlterGymInformation) }
        ];
    }, [session]);

    const employerMenuItems: MenuItemProps[] = useMemo(() => {
        return [
            { title: t('employer.dashboard'), icon: 'dashboard', to: '/employer', hasRight: hasRole(Role.Admin) || hasRole(Role.Employer) },
            { title: t('employer.employees'), icon: 'employees', to: '/employer/employees', hasRight: hasRole(Role.Admin) || hasRole(Role.Employer) },
            { title: t('employer.challenges'), icon: 'challenge', to: '/employer/challenges', hasRight: hasRole(Role.Admin) || hasRole(Role.Employer) },
            { title: t('employer.transactions'), icon: 'invoice', to: '/employer/invoices', hasRight: hasRole(Role.Admin) || hasRole(Role.Employer) },
            { title: t('employer.shop'), icon: 'cart', to: '/employer/shop', hasRight: hasRole(Role.Admin) || hasRole(Role.Employer) },
            { title: t('employer.settings'), icon: 'settings', to: '/employer/settings', hasRight: hasRole(Role.Admin) || hasRole(Role.Employer) },
        ];
    }, [session]);

    // prettier-ignore
    const locationMenuItems: MenuItemProps[] = useMemo(() => {
        return [
            { title: t('location.dashboard'), icon: 'dashboard', to: '/location' },
            { title: t('location.employees'), icon: 'employees', to: '/location/employees', hasRight: hasGymRight(GymRight.ManageMembers) || hasAccessToLocationRight(LocationRight.ManageEmployees) },
            // { title: t('location.employers'), icon: 'employer', to: '/location/employers' },
            // { title: t('location.members'), icon: 'members', to: '/location/members', hasRight: hasGymRight(GymRight.ManageMembers) || hasAccessToLocationRight(LocationRight.ManageMemberships) },
            // { title: t('location.rights'), icon: 'rights', to: '/location/rights', hasRight: hasGymRight(GymRight.ManageLocationRights) || hasAccessToLocationRight(LocationRight.ManageLocationRights) },
            // { title: t('location.payments'), icon: 'invoice', to: '/location/invoices', hasRight: hasGymRight(GymRight.ManageMemberships) || hasAccessToLocationRight(LocationRight.ManageMemberships) },
            // { title: t('location.lessons'), icon: 'lesson', to: '/location/lessons', hasRight: hasAccessToLocationRight(LocationRight.ManageMemberships) },
            // { title: t('location.areas'), icon: 'area', to: '/location/areas', hasRight: hasGymRight(GymRight.ManageLocationAreas) || hasAccessToLocationRight(LocationRight.ManageLocationAreas) },
            // { title: t('location.reports'), icon: 'report', to: '/location/reports' },
            { title: t('location.productOrders'), icon: 'euro', to: '/location/productOrders' },
            { title: t('location.settings'), icon: 'settings', to: '/location/settings/general', hasRight: hasGymRight(GymRight.AlterGymInformation) || hasAccessToLocationRight(LocationRight.AlterLocationInformation) },
            // { title: t('location.challenges'), icon: 'challenge', to: '/location/challenges', hasRight: hasRole(Role.Admin) }
        ];
    }, [session]);

    const adminMenuItems: MenuItemProps[] = useMemo(() => {
        return [
            { title: t('admin.dashboard'), icon: 'dashboard', to: '/admin' },
            { title: t('admin.admins'), icon: 'admin', to: '/admin/admins' },
            { title: t('admin.gyms'), icon: 'gym', to: '/admin/gyms' },
            { title: t('admin.associations'), icon: '', to: '/admin/associations' },
            { title: t('admin.employers'), icon: 'employer', to: '/admin/employers' },
            { title: t('employer.transactions'), icon: 'invoice', to: '/admin/xptransactions' },
            { title: t('admin.store'), icon: 'euro', to: '/admin/store' },
            { title: t('admin.challengeTemplate'), icon: 'challenge', to: '/admin/challengeTemplate' },
            { title: t('admin.contentEnvironment'), icon: 'marker', to: '/admin/contentEnvironment' },
            { title: t('admin.userOverview'), icon: 'employees', to: '/admin/userOverview' },
            { title: t('admin.pictureBank'), icon: '', to: '/admin/pictureBank' },
            { title: t('admin.suppliers'), icon: '', to: '/admin/suppliersOverview' },
            { title: t('admin.commissionDetermined'), icon: '', to: '/admin/commissionDeterminedOverview' },
        ];
    }, [session]);

    const associationMenuItems: MenuItemProps[] = useMemo(() => {
        return [
            { title: t('association.dashboard'), icon: 'dashboard', to: '/association' },
            { title: t('association.locations'), icon: '', to: '/association/locations' },
            { title: t('association.challenges'), icon: 'challenge', to: '/association/challenges' }
        ];
    }, [session]);

    const tipsAndTricksMenuItems: MenuItemProps[] = useMemo(() => {
        return [
            { title: t('tipsAndTricks.recipes'), icon: 'challenge', to: '/tipsAndTricks/recipes', hasRight: hasRole(Role.ContentCreator) },
            { title: t('tipsAndTricks.blogs'), icon: 'marker', to: '/tipsAndTricks/blogs', hasRight: hasRole(Role.ContentCreator) },
            { title: t('tipsAndTricks.workouts'), icon: 'members', to: '/tipsAndTricks/workouts', hasRight: hasRole(Role.ContentCreator) }
        ];
    }, [session]);

    const ContentMenuItems: MenuItemProps[] = useMemo(() => {
        return [
            { title: t('contentEnvironment.dashboard'), icon: 'dashboard', to: '/content', hasRight: hasRole(Role.Admin) },
            { title: t('contentEnvironment.contentCreators'), icon: 'members', to: '/content/creators', hasRight: hasRole(Role.Admin) },
            { title: t('contentEnvironment.recipe'), icon: 'challenge', to: '/content/recipes', hasRight: hasRole(Role.Admin) },
            { title: t('contentEnvironment.blog'), icon: 'marker', to: '/content/blogs', hasRight: hasRole(Role.Admin) },
            { title: t('contentEnvironment.workout'), icon: 'marker', to: '/content/workout', hasRight: hasRole(Role.Admin) },
            { title: t('contentEnvironment.settings'), icon: 'settings', to: '/content/settings', hasRight: hasRole(Role.Admin) },
        ];
    }, [session]);

    const supplierItems: MenuItemProps[] = useMemo(() => {
        return [
            { title: t('supplier.dashboard'), icon: 'dashboard', to: '/supplier', hasRight: hasRole(Role.Supplier) || hasRole(Role.Admin) },
            { title: t('supplier.productsOverview'), icon: 'employees', to: '/supplier/products', hasRight: hasRole(Role.Supplier) || hasRole(Role.Admin) },
            { title: t('supplier.productOrders'), icon: 'employees', to: '/supplier/productOrders', hasRight: hasRole(Role.Supplier) || hasRole(Role.Admin) },
        ];
    }, [session]);

    return {
        gymMenuItems,
        employerMenuItems,
        locationMenuItems,
        adminMenuItems,
        associationMenuItems,
        tipsAndTricksMenuItems,
        ContentMenuItems,
        supplierItems
    };
};
