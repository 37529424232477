import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Line = styled.div<{ last?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
    align-items: center;
    font-weight: 600;
    border-bottom: ${({ theme, last }) => (!last ? `1px solid ${theme.colors.light}` : 'none')};
`;

export const Key = styled.span<{ small?: boolean }>`
    font-size: 0.8rem;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ small }) =>
    small &&
    css`
        font-size: .6875rem;
    `}
`;

export const Value = styled.div<{ small?: boolean; highlightValue?: boolean }>`
    font-size: 1.1rem;
    line-height: 1;
    ${({ small }) =>
    small &&
    css`
        font-size: 1rem;
    `}
    ${({ highlightValue, theme }) =>
    highlightValue &&
    css`
        color: ${theme.colors.blue};
    `}
`;