// @ts-nocheck not sure how to fix the StyledComponent types
/* eslint-disable @typescript-eslint/ban-types */
import React, { Ref, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { UseTableOptions, useTable, useGlobalFilter, TableState, TableInstance, usePagination, useRowSelect, useFlexLayout, useSortBy } from 'react-table';
import { Wrapper, Table as StyledTable, Th, THead, Td, Tr, TableWrapper, EmptyList } from './styles';
import Search from './components/Search';
import Footer from './components/Footer';
import Margin from 'components/atoms/Margin';
import TableLoading from '../TableLoading';
import useActions from './hooks/useActions';
import useRowSelection from './hooks/useRowSelection';
import { Action } from 'components/atoms/Action';
import Icon from 'components/atoms/Icon';
import { H1, H4 } from 'components/atoms/text';
import { Button } from 'components/atoms/button';
import { useTranslation } from 'react-i18next';
import { Group } from 'components/molecules/Group/styles';
import Tabs, { Tab } from 'components/molecules/Tabs';

export interface RowProps<T extends object> {
    onClick?: (item: T) => void;
}

export interface TableTab<T extends object> extends Omit<Tab, 'value' | 'amount'> {
    filter: (item: T) => boolean;
}

interface TableProps<T extends object> extends UseTableOptions<T> {
    search?: boolean;
    defaultFilterValue?: string | number
    onAddClick?: () => void;
    onAddClickLabel?: string;
    tabs?: TableTab<T>[]
    actions?: Action<T>[];
    isLoading?: boolean;
    isRowSelect?: boolean;
    onStateChange?: (state: TableState<T>) => void;
    row?: RowProps<T>;
}

function Table<T extends object>(
    { columns, data, search, onAddClick, onAddClickLabel, tabs, actions, isLoading, initialState, row: rowProps, isRowSelect, onStateChange }: TableProps<T>,
    ref: Ref<TableInstance<T>>
) {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const { t } = useTranslation('common');

    // Methods.
    const stateReducer = useCallback(
        (newState: TableState<T>) => {
            if (onStateChange) {
                onStateChange(newState);
            }
            return newState;
        },
        [onStateChange]
    );

    const filteredData = useMemo(() => tabs && tabs.length > 0 ? data.filter(tabs[tabIndex].filter) : data, [tabIndex, data]);

    // Hooks.
    const instance = useTable<T>(
        {
            columns,
            data: filteredData,
            initialState,
            stateReducer,
        },
        useGlobalFilter,
        useFlexLayout,
        useSortBy,
        usePagination,
        useRowSelect,
        // Custom hooks.
        useActions<T>(actions),
        useRowSelection<T>(isRowSelect)
    );

    useImperativeHandle(ref, () => instance);

    // Render.
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        setGlobalFilter,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        visibleColumns,
        state: { pageIndex, globalFilter, pageSize },
        pageCount,
        gotoPage
    } = instance;

    const resetFilter = () => {
        setGlobalFilter('');
    };

    // Render.
    const calculatedTabs: Tab[] = tabs ? tabs.map((tab, index) => ({ ...tab, value: index, amount: data.filter(tab.filter).length })) : undefined;

    return (
        <>
            {(calculatedTabs || search) && (
                <Margin bottom={1}>
                    {(calculatedTabs || onAddClick) &&
                        <Group spaceBetween={!!calculatedTabs} right={!calculatedTabs}>
                            {calculatedTabs &&
                                <Tabs tabs={calculatedTabs} current={tabIndex} setCurrent={setTabIndex} />
                            }
                            {onAddClick && !search &&
                                <Button alignSelf="center" rounded brand="pink" onClick={onAddClick}>{onAddClickLabel ? onAddClickLabel : 'Toevoegen'}</Button>
                            }
                        </Group>
                    }
                    {search && (
                        <Search filter={globalFilter} setFilter={setGlobalFilter} onAddClick={onAddClick} onAddClickLabel={onAddClickLabel} />
                    )}
                </Margin>
            )}
            <Wrapper>
                <TableWrapper>
                    <StyledTable {...getTableProps()}>
                        <THead>
                            {headerGroups.map((headerGroup) => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>{column.isSorted ? (column.isSortedDesc ? <Icon name="chevron-down" /> : <Icon name="chevron-up" />) : ''}</span>
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </THead>
                        {isLoading ? (
                            <TableLoading<T> columns={visibleColumns} />
                        ) : (
                            <tbody {...getTableBodyProps()}>
                                {page.length > 0 ? (
                                    page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <Tr {...row.getRowProps()} onClick={() => rowProps?.onClick && rowProps.onClick(row.original)}>
                                                {row.cells.map((cell) => {
                                                    return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>;
                                                })}
                                            </Tr>
                                        );
                                    })
                                ) : (
                                    <EmptyList>
                                        <td colSpan="all">
                                            <Margin bottom={1}>
                                                <Icon name="dashboard" size={3} />
                                            </Margin>
                                            <Margin bottom={1}>
                                                <H1 noMargin>{t('listview.notification')}</H1>
                                            </Margin>
                                            <Margin bottom={2}>
                                                <H4 grey={300}>{t('listview.no-results')}</H4>
                                            </Margin>
                                            {(globalFilter) &&
                                                <Button alignSelf="center" rounded brand="pink" onClick={resetFilter}>{t('listview.reset-filter')}</Button>
                                            }
                                        </td>
                                    </EmptyList>
                                )}
                            </tbody>
                        )}
                    </StyledTable>
                </TableWrapper>
                <Footer
                    totalRows={rows.length}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                />
            </Wrapper>
        </>
    );
}

const TableWithRef = React.forwardRef(Table) as <T extends object>(
    props: TableProps<T> & { ref?: Ref<TableInstance<T>> }
) => ReturnType<typeof Table>;

export default TableWithRef;
