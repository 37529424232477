import { Interpolation, keyframes } from '@emotion/react';
import theme, { Theme } from 'static/theme';

const spin = keyframes`
0% {
    transform: rotate(0deg)
}

50% {
    transform: rotate(180deg)
}

to {
    transform: rotate(1turn)
}
`;

export const spinner: Interpolation<Theme> = {
    display: 'inline-block',
    border: '4px solid ' + theme.colors.primary,
    borderLeftColor: 'transparent !important',
    borderRadius: '100%',
    marginLeft: 7,
    animation: spin + ' .4s linear infinite'
};
