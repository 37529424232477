import React, { FC } from 'react';
import { FormikFormControl, FormGroup } from 'components/atoms/form';
import { useTranslation } from 'react-i18next';
import Product from 'components/molecules/Product';
import { Card, CardBody } from 'components/atoms/card';
import Group from 'components/molecules/Group';
import { PriceText } from '../styles';
import { useFormikContext } from 'formik';
import { ProductValues } from '..';
import { StoreItemCategory } from 'hooks/store';
import { formatCurrency } from 'utils/numeralFormatter';

const Step2: FC = () => {
    const { t } = useTranslation('productModal');
    const { values } = useFormikContext<ProductValues>();


    return (
        <>
            <FormGroup label={t('step2.price')} required>
                <FormikFormControl name="cost" placeholder={t('step2.price')} />
            </FormGroup>
            <FormGroup label={t('step2.stock')} required>
                <FormikFormControl name="stock" placeholder={t('step2.stock')} />
            </FormGroup>
            <Card variant="background" noShadow>
                <CardBody>
                    <Group spaceBetween css={{ alignItems: 'flex-end' }}>
                        <Product
                            big
                            image="https://www.bluenotion.nl/media/2gukfcce/schermafbeelding-2021-06-25-om-15-04-16.png?anchor=center&mode=crop&width=200&height=200"
                            name={values.name}
                            specs={[`Categorie: ${StoreItemCategory[values.storeItemCategory]}`]}
                        />
                        <PriceText>
                            {`Prijs: ${formatCurrency(values.cost)}`}<br />
                            {values.stock && `Voorraad: ${values.stock} stuks`}
                        </PriceText>
                    </Group>
                </CardBody>
            </Card>

        </>
    );
};

export default Step2;