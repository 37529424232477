import React from 'react';
import { FormSelect as Control, Value, Wrapper } from './styles';
import InvalidFeedback from '../InvalidFeedback';
import Dropdown from 'components/molecules/dropdown';
import { DropdownItemProps } from 'components/atoms/item/DropdownItem';

export interface Option<T extends number | string> extends DropdownItemProps {
    value: T;
    title: string;
    data?: any;
}

export interface FormSelectProps<T extends number | string> {
    error?: string;
    value?: T;
    inline?: boolean;
    options?: DropdownItemProps[];
    onChange?: (value: T) => void;
    isLight?: boolean;
}

const FormSelect = <T extends number | string>({ error, value, onChange, inline, options, isLight }: FormSelectProps<T>) => {
    const label = options?.find((option) => option.value === value);

    return (
        <>
            <Wrapper>
                <Control inline={inline} isLight={isLight}>
                    <Dropdown
                        isFull={!isLight}
                        items={(close) =>
                            options?.map((option) => ({
                                ...option,
                                onClick: () => {
                                    if (onChange) {
                                        onChange(option.value as T);
                                    }

                                    if (close) {
                                        close();
                                    }
                                }
                            })) || []
                        }
                    >
                        <Value>{label?.title}</Value>
                    </Dropdown>
                </Control>
            </Wrapper>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormSelect;

