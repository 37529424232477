import React, { FC } from 'react';
import { Claim, Right, Role, GymRight, LocationRight } from 'utils/constants';
import { ClaimModel } from 'api';

export interface Session {
    userId?: string;
    role?: Role;
    rights: Right[];
    activeGymId?: string;
    activeLocationId?: string;
    activeEmployerId?: string;
    activeAssociationId?: string;
    activeContentEnvironmentId?: string;
    activeSupplierId?: string;
    activeEnvironment: ActiveEnvironmentEnum;
    gymRights: string[];
    locationRights: string[];
}

export enum ActiveEnvironmentEnum {
    Admin = 0,
    Gym = 1,
    Customer = 2,
    Location = 3,
    Employer = 4,
    Association = 5,
    TipsAndTricks = 6,
    Content = 7,
    Supplier = 8
}

export interface SessionContextValue {
    session?: Session;
    setSession: (claims: ClaimModel[]) => Session;
    clearSession: () => void;
    hasRight: (right: Right) => boolean;
    hasRole: (role: Role) => boolean;
    hasGymRight: (gymRight: GymRight) => boolean;
    hasLocationRight: (locationRight: LocationRight, locationId?: string) => boolean;
    hasAccessToLocationRight: (locationRight: LocationRight, locationId?: string) => boolean;
}

function convertToSession(claims: ClaimModel[]) {
    function claimValues(claimName: Claim) {
        const claim = claims.find((c) => c.type === claimName);
        return claim
            ? claim.values
            : undefined;
    }

    function claimFirstValue(claimName: Claim) {
        const values = claimValues(claimName);
        return values
            ? values[0]
            : undefined;
    }

    const userId = claimFirstValue(Claim.UserId);
    const role = claimFirstValue(Claim.Roles) as Role;
    const rights = claimValues(Claim.Rights) as Right[];
    const activeGymId = claimFirstValue(Claim.ActiveGymId);
    const activeLocationId = claimFirstValue(Claim.ActiveLocationId);
    const activeEmployerId = claimFirstValue(Claim.ActiveEmployerId);
    const activeAssociationId = claimFirstValue(Claim.ActiveAssociationId);
    const activeContentEnvironmentId = claimFirstValue(Claim.ActiveContentEnvironmentId);
    const activeSupplierId = claimFirstValue(Claim.ActiveSupplierId);
    const gymRights = claimValues(Claim.GymRights) ?? [];
    const locationRights = claimValues(Claim.LocationRights) ?? [];

    const activeEnvironmentClaim = claimFirstValue(Claim.ActiveEnvironment);
    const activeEnvironment = activeEnvironmentClaim
        ? parseInt(activeEnvironmentClaim, 10) as ActiveEnvironmentEnum
        : ActiveEnvironmentEnum.Gym;

    const newSession: Session = {
        userId,
        role,
        rights,
        activeGymId: activeGymId,
        activeLocationId,
        activeEmployerId,
        activeAssociationId,
        activeContentEnvironmentId,
        activeSupplierId,
        activeEnvironment,
        gymRights,
        locationRights
    };

    return newSession;
}

const SessionContext = React.createContext<SessionContextValue>({
    session: undefined,
    setSession: (claims: ClaimModel[]) => {
        return convertToSession(claims);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearSession: () => { },
    hasRight: (right: Right) => false,
    hasRole: (role: Role) => false,
    hasGymRight: (gymRight: GymRight) => false,
    hasLocationRight: (locationRight: LocationRight) => false,
    hasAccessToLocationRight: (locationRight: LocationRight) => false
});

interface SessionProviderProps {

}

export const SessionProvider: FC<SessionProviderProps> = (props) => {
    const [session, setSession] = React.useState<Session>();

    const updateSession = React.useCallback((claims: ClaimModel[]) => {
        const newSession = convertToSession(claims);
        setSession(newSession);

        return newSession;
    }, [setSession]);

    const clearSession = React.useCallback(() => {
        setSession(undefined);
    }, [setSession]);

    const hasRight = React.useCallback((right: Right) => {
        return session?.rights?.includes(right) ?? false;
    }, [session]);

    const hasGymRight = React.useCallback((gymRight: GymRight) => {
        if (!session) {
            return false;
        }
        if (hasRole(Role.Admin)) {
            return true;
        } else {
            return session.gymRights.includes(`${session.activeGymId}:${gymRight}`) ?? false;
        }
    }, [session]);

    const hasLocationRight = React.useCallback((locationRight: LocationRight, locationId?: string) => {
        if (!session) {
            return false;
        }

        return session.locationRights.includes(`${locationId ?? session.activeLocationId}:${locationRight}`) ?? false;
    }, [session]);

    const hasAccessToLocationRight = React.useCallback((locationRight: LocationRight, locationId?: string) => {
        if (!session) {
            return false;
        }

        if (hasRole(Role.Admin)) {
            return true;
        }

        return hasLocationRight(locationRight, locationId);
    }, [session]);

    const hasRole = React.useCallback((role: Role) => {
        return session?.role === role ?? false;
    }, [session]);

    const context: SessionContextValue = React.useMemo(() => ({
        session,
        setSession: updateSession,
        clearSession,
        hasRight,
        hasRole,
        hasGymRight,
        hasLocationRight,
        hasAccessToLocationRight
    }), [session, updateSession, clearSession, hasRight, hasRole, hasGymRight, hasLocationRight, hasAccessToLocationRight]);

    return (
        <SessionContext.Provider value={context} {...props} />
    );
};

export const useSession = () => {
    const context = React.useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }

    return context;
};
