import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransitionStatus, ENTERING, ENTERED, EXITING, EXITED } from 'react-transition-group/Transition';

export const Overlay = styled.div<{ state: TransitionStatus; duration: number }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    z-index: 102;
    transition: opacity ${({ duration }) => duration}ms ease;
    opacity: 0;

    ${({ state }) =>
        (state === ENTERING || state === ENTERED) &&
        css`
            opacity: 1;
        `}

    ${({ state }) =>
        (state === EXITING || state === EXITED) &&
        css`
            opacity: 0;
        `}
`;

export const Panel = styled.div<{ state: TransitionStatus; duration: number }>`
    width: 760px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    height: calc(100% - 1.5rem);
    margin-top: 1.5rem;
    display: flex;
    flex-direction: colomn;
    transition: transform ${({ duration }) => duration}ms ease;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div<{ decorations: string }>`
    padding: 1.5rem 56px;
    border-bottom-left-radius: 32px;
    overflow-y: auto;
    flex: 1;
    background: ${({ decorations }) => `url(${decorations})`};
    background-size: 544px 527px;
    background-position: right bottom;
    background-repeat: no-repeat;
    background: ${({ decorations }) => `url(${decorations})`};
    background-size: 544px 527px;
    background-position: right bottom;
    background-repeat: no-repeat;
`;

export const Header = styled.div`
    display: flex;
    padding: 3.5rem 56px;
    background: linear-gradient(
        115deg,
        rgba(89, 114, 217, 0.05) -14.81%,
        rgba(246, 174, 45, 0.05) 46.11%,
        rgba(237, 101, 148, 0.05) 87.11%
    );
`;

export const HeaderText = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    border-top-left-radius: 32px;
    /* opacity: 0.25; */
`;

export const IconWrapper = styled.div``;

export const Title = styled.span`
    color: #1c1c1c;
    font-size: 1.75rem;
    line-height: 1;
    font-weight: 700;
`;

export const Subtitle = styled.span`
    color: #1c1c1c;
    margin-top: 8px;
    font-size: 1.125rem;
    line-height: 1;
`;

