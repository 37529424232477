import React, { FC } from 'react';
import { DragDropContext, Draggable, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { FieldArray } from 'formik';
import colors from 'static/theme/colors';
import { EditFitnessProductModel, FitnessProductFileModel } from 'hooks/fitnessProducts';
import { Col, Row } from 'react-grid-system';
import { Button } from 'components/atoms/button';
import { cdnFileUrl } from 'utils/cdnHelper';
import { FormGroup, FormikFormDragDropFile } from '..';
import DragButton from 'templates/FitnessProducts/components/DragButton';
import { useTranslation } from 'react-i18next';
import { Buttons } from '../FormButtonSelect/styles';
import InvalidFeedback from '../InvalidFeedback';

interface FormGroupProps {
    name: string;
    title: string;
    subtitle: string;
    values: EditFitnessProductModel;
    error?: string;
}

const defaultFileModel: FitnessProductFileModel = {
    id: undefined,
    image: undefined,
    imageHash: undefined,
    order: 0
};

const FormDragAndDrop: FC<FormGroupProps> = ({ name, title, subtitle, values, error }) => {
    const { t } = useTranslation('templateFitnessProducts');

    const handleOnDragEnd = (arrayHelpers: any) => (result: any) => {
        if (result.destination === null) return;
        arrayHelpers.swap(result.source.index, result.destination.index);
    };

    return (
        <Row>
            <Col>
                <FormGroup
                    label={title}
                    required
                />
                <p>{subtitle}</p>
            </Col>
            <Col offset={{ xs: 1 }}>
                <FieldArray name={name}>
                    {(arrayHelpers) => (
                        <DragDropContext onDragEnd={handleOnDragEnd(arrayHelpers)}>
                            <Droppable droppableId={`${name}-item`}>
                                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                    <>
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {values.images && values.images.length > 0 && (
                                                values.images.map((image, index) => (
                                                    <Draggable index={index} key={index} draggableId={`${name}[${index}]`}>
                                                        {(provided, snapshot) => (
                                                            <div key={index} ref={provided.innerRef} {...provided.draggableProps}>
                                                                <Row style={{ backgroundColor: colors.white, padding: '5px', minHeight: '100px' }}>
                                                                    <Col>
                                                                        <FormikFormDragDropFile
                                                                            name={`${name}.${index}.image`}
                                                                            image={image.image
                                                                                ? URL.createObjectURL(image.image)
                                                                                : (image.imageHash
                                                                                    ? cdnFileUrl(image.imageHash, null)
                                                                                    : undefined)}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <p>{image.image ? image.image.name : ''}</p>
                                                                    </Col>
                                                                    <Col offset={{ xs: 1 }}>
                                                                        <Buttons style={{ alignSelf: 'end', border: 'none' }}>
                                                                            <DragButton startIcon={'move'} draggable={provided.dragHandleProps} />
                                                                            <Button
                                                                                size="md"
                                                                                type="button"
                                                                                startIcon="delete"
                                                                                iconColor={colors.black}
                                                                                white
                                                                                onClick={(() => arrayHelpers.remove(index))}
                                                                            />
                                                                        </Buttons>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))
                                            )}
                                            {provided.placeholder}
                                        </div>
                                        <div>
                                            <Row style={{ justifyContent: 'flex-end' }}>
                                                <Button
                                                    size="md"
                                                    type="button"
                                                    title={t('button.addImage')}
                                                    brand="yellow"
                                                    onClick={() => arrayHelpers.push(defaultFileModel)}
                                                />
                                            </Row>
                                        </div>
                                    </>
                                )}

                            </Droppable>
                        </DragDropContext>
                    )}
                </FieldArray>
                {error && <InvalidFeedback>{error}</InvalidFeedback>}
            </Col>
        </Row>
    );
};

export default FormDragAndDrop;
