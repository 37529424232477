import React, { FC, MouseEvent } from 'react';
import { Button as Wrapper } from './styles';
import { Brand } from 'static/theme/brand';
import Icon from 'components/atoms/Icon';
import colors from 'static/theme/colors';

export interface RoundButtonProps {
    onClick?: (event?: MouseEvent) => void;
    brand?: keyof Brand;
    className?: string;
    loading?: boolean;
    type?: 'button' | 'submit';
    tabIndex?: number;
    noFocus?: boolean;
    icon: string;
    // Variants.
    disabled?: boolean;
    white?: boolean;
}

const RoundButton: FC<RoundButtonProps> = ({ loading, disabled, onClick, children, brand = 'blue', type, ...props }) => {

    // Methods.
    const handleOnClick = (event?: MouseEvent) => {
        if (onClick && (!loading || disabled)) {
            onClick(event);
        }
    };

    // Render.
    return (
        <Wrapper disabled={disabled || loading} onClick={handleOnClick} type={type} brand={brand} {...props}>
            <Icon name={props.icon} color={colors.white} size={1} />
        </Wrapper>
    );
};

export default RoundButton;
