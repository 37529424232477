import { useApi } from 'hooks/api';

export const useEmployerTransactions = () => {
    const { callApi } = useApi();

    const addBalance = async (amountOfXp: number, employerId: string) => {
        const response = await callApi.current<string>(`/Employer/${employerId}/AddBalance`, {
            method: 'POST',
            body: JSON.stringify({
                amountOfXp
            })
        });

        return response;
    };

    const getBalance = async (employerId: string) => {
        const response = await callApi.current<number>(`/Employer/${employerId}/GetBalance`);

        return response;
    };

    return {
        addBalance,
        getBalance
    };
};
