import Button, { ButtonProps } from 'components/atoms/button/Button';
import { H3, H4 } from 'components/atoms/text';
import React, { FC } from 'react';
import { Header, Content, Right } from './styles';

export interface CardHeaderProps {
    title: string;
    cta?: string;
    ctaAction?: () => void;
    button?: ButtonProps;
    notifications?: number;
    icon?: string;
}

const CardHeader: FC<CardHeaderProps> = ({ title, cta, button, notifications, ctaAction, children, icon }) => {
    return (
        <Header>
            <H3 icon={icon} notifications={notifications}>
                {title}
            </H3>
            {children && <Content>{children}</Content>}
            <Right>
                {cta && <H4 onClick={ctaAction}>{cta}</H4>}
                {button && <Button {...button} />}
            </Right>
        </Header>
    );
};

export default CardHeader;
