import React, { FC } from 'react';
import { FormikFormControl, FormGroup, FormikFormTextarea, FormikFormDropFile, FormikFormButtonSelect } from 'components/atoms/form';
import { useTranslation } from 'react-i18next';
import { StoreItemCategory } from 'hooks/store';
import { ProductValues } from '..';
import { useFormikContext } from 'formik';
import { cdnFileUrl } from 'utils/cdnHelper';


const Step1: FC = () => {
    const { t } = useTranslation('productModal');
    const { values } = useFormikContext<ProductValues>();

    return (
        <>
            <FormGroup>
                <FormikFormDropFile
                    name="image"
                    label={t('step1.image')}
                    image={values.image
                        ? URL.createObjectURL(values.image)
                        : (values.cdnHash
                            ? cdnFileUrl(values.cdnHash, null)
                            : undefined)} 
                />
            </FormGroup>
            <FormGroup label={t('step1.name')} required>
                <FormikFormControl name="name" placeholder={t('step1.name')} />
            </FormGroup>
            <FormGroup label={t('step1.category')} required>
                <FormikFormButtonSelect<StoreItemCategory> // if you want value to be a number, change it to number here.
                    name="storeItemCategory"
                    stretch
                    options={[
                        {
                            value: 0,
                            title: t('step1.categories.none')
                        },
                        {
                            value: 1,
                            title: t('step1.categories.gymClothing')
                        },
                        {
                            value: 2,
                            title: t('step1.categories.accessories')
                        },
                        {
                            value: 3,
                            title: t('step1.categories.tickets')
                        }
                    ]}
                />
            </FormGroup>
            <FormGroup label={t('step1.description')} required>
                <FormikFormTextarea name="description" placeholder={t('step1.description')} />
            </FormGroup>
        </>
    );
};

export default Step1;
