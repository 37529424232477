import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import Wizard from 'components/organisms/Wizard';
import Step1 from './components/Step1';

interface PointsModalProps {
    onSubmit: (amount: number) => void;
}

export interface PointsValues {
    points: number;
}

const initialValues: PointsValues = {
    points: 0
};

const usePointsModal = (pointsModalProps: PointsModalProps): [(currentPoints: number) => void, () => void, boolean] => {
    const [currentPoints, setCurrentPoints] = useState<number>(0);

    // Methods.
    const handleOnSubmit = (values: PointsValues) => {
        pointsModalProps.onSubmit(values.points);
    };

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal onHide={hide} inProp={inProp} onExited={onExited} title="XP kopen" titleRight="1xp = &euro;0,01" size="large">
                <Wizard<PointsValues>
                    initialValues={initialValues}
                    onSubmit={handleOnSubmit}
                    onSubmitLabel="Naar betalen"
                    hideStepper
                    steps={[
                        {
                            title: 'Exp kopen',
                            child: <Step1 currentPoints={currentPoints} />,
                            step: 1 // Number that is being displayed.
                        }
                    ]}
                />
            </Modal>
        ),
        []
    );

    const showModal = useCallback(async (currentPoints: number) => {
        setCurrentPoints(currentPoints);
        show();
    }, [show]);

    return [showModal, hide, isShowing];
};

export default usePointsModal;
