import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormGroup } from 'components/atoms/form';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { cdnFileUrl } from 'utils/cdnHelper';
import { ChallengeTemplateValues } from '..';
import { Button } from 'components/atoms/button';
import Margin from 'components/atoms/Margin';
import { H3 } from 'components/atoms/text';
import Dropdown from 'components/molecules/dropdown';
import Group from 'components/molecules/Group';
import PictureBankImage from 'templates/ChallengeTemplate/components/PictureBankImage';
import PictureBankImages from 'templates/ChallengeTemplate/components/PictureBankImages';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';
import { PictureBankThemeProps } from 'templates/ChallengeTemplate/components/PictureBankTheme';
import { PictureBankInputModel, PictureBankTagType, usePictureBank } from 'hooks/pictureBank';
import ImageButton from './ImageButton/ImageButton';
import useShowImageModal from 'screens/AdminEnvironment/Store/components/ShowImageModal';
import Loading from 'components/atoms/Loading';

interface Step2Props {
    mode: 'edit' | 'add';
}

const AllTheme = [PictureBankTagType.Movement, PictureBankTagType.Nutrition, PictureBankTagType.Relaxation];

const Step2: FC<Step2Props> = ({ mode }) => {
    const { t } = useTranslation('challengeTemplate');
    const { values, setFieldValue } = useFormikContext<ChallengeTemplateValues>();
    const [isImagePicking, setIsImagePicking] = useState<boolean>(false);
    const [pictureBankItems, setPictureBankItems] = useState<PictureBankInputModel[]>([]);
    const [shownPictureBankItems, setShownPictureBankItems] = useState<PictureBankInputModel[]>([]);
    const [pictureBankThemeId, setPictureBankThemeId] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedPictureBankTheme, setSelectedPictureBankItemTheme] = useState<PictureBankTagType[]>(AllTheme);
    const [selectedPicture, setSelectedPicture] = useState<PictureBankInputModel>();
    const { getAllPictureBankItems } = usePictureBank();

    const fetchPictureBankItems = async () => {
        setIsLoading(true);
        const response = await getAllPictureBankItems({ isActive: true });
        if (response && response.data) {
            const data = response.data;
            setPictureBankItems(data);
            setShownPictureBankItems(data);
            setSelectedPictureBankItemTheme([PictureBankTagType.Movement, PictureBankTagType.Nutrition, PictureBankTagType.Relaxation]);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchPictureBankItems();
        setIsImagePicking(values.challengeBannerHash === undefined || values.challengeBannerHash === '');
    }, []);

    useEffect(() => {
        setShownPictureBankItems(pictureBankItems.filter(p => selectedPictureBankTheme.includes(p.pictureBankTagType)));
    }, [selectedPictureBankTheme]);

    const handleSelectPictureItem = (model: PictureBankInputModel) => {
        setFieldValue('pictureBankItemModel', model);
        setFieldValue('pictureBankItemId', model.id);
        setFieldValue('challengeBannerHash', model.pictureHash);
        setSelectedPicture(model);
    };

    const handleClick = (value: PictureBankTagType[], id: string) => {
        setSelectedPictureBankItemTheme(value);
        setPictureBankThemeId(id);
    };

    const themes: (DropdownItemProps & Omit<PictureBankThemeProps, 'onClick'>)[] = useMemo(
        () => [
            { id: '0', image: 'https://picsum.photos/400/300?random=1', title: t('imageBank.themes.all'), theme: AllTheme },
            { id: '1', image: 'https://picsum.photos/400/300?random=2', title: t('imageBank.themes.movement'), theme: [PictureBankTagType.Movement] },
            { id: '2', image: 'https://picsum.photos/400/300?random=3', title: t('imageBank.themes.foodAndDrinks'), theme: [PictureBankTagType.Nutrition] },
            { id: '3', image: 'https://picsum.photos/400/300?random=4', title: t('imageBank.themes.relaxation'), theme: [PictureBankTagType.Relaxation] }
        ],
        [t]
    );

    const handleOnConfirmImagePicking = () => {
        setIsImagePicking(!isImagePicking);
    };

    // modals
    const [showImage] = useShowImageModal();

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {
                values.challengeBannerHash !== undefined && values.challengeBannerHash !== '' && !isImagePicking &&
                <>
                    <FormGroup label={t('imageBank.title')}>
                        <ImageButton onClick={handleOnConfirmImagePicking} image={cdnFileUrl(values.challengeBannerHash, null)} />
                    </FormGroup>
                </>
            }
            {isImagePicking &&
                <>
                    <Margin bottom={1}>
                        <Group spaceBetween>
                            <H3>{t('imageBank.title')}</H3>
                            <Dropdown
                                items={themes.map((theme) => ({
                                    title: theme.title,
                                    value: theme.id,
                                    onClick: () => { handleClick(theme.theme, theme.id); }
                                }))}
                            >
                                {pictureBankThemeId
                                    ? themes.find((theme) => theme.id === pictureBankThemeId)?.title
                                    : themes[0].title}
                            </Dropdown>
                        </Group>
                    </Margin>
                    <PictureBankImages>
                        {/* TODO use (square!) cdn image */}
                        {
                            shownPictureBankItems.map((image) => (
                                <PictureBankImage
                                    model={image} key={image.id}
                                    {...image}
                                    image={cdnFileUrl(image.pictureHash, null)}
                                    isActive={selectedPicture === image}
                                    onClick={() => handleSelectPictureItem(image)}
                                />
                            ))
                        }
                    </PictureBankImages>
                    <Margin bottom={1}>
                        <Group spaceBetween>
                            <Button
                                disabled={!selectedPicture}
                                brand="yellow"
                                title={t('imageBank.seeImage')}
                                type="button"
                                onClick={() => showImage(selectedPicture?.pictureHash ?? '')}
                            />
                            <Group>
                                <Button disabled={!selectedPicture} brand="yellow" onClick={handleOnConfirmImagePicking} type="button">
                                    {t('imageBank.applyImage')}
                                </Button>
                            </Group>
                        </Group>
                    </Margin>
                </>
            }
        </>
    );
};

export default Step2;
