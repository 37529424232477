import { useApi } from 'hooks/api';
import { formatNumber } from 'utils/numeralFormatter';
import { appendObject } from 'utils/objectAppender';

export enum StoreItemCategory {
    None = 0,
    SportClothes = 1,
    Accessories = 2,
    Tickets = 3,
}

export interface StoreItemPlainModel {
    id: string,
    name: string,
    description: string,
    cost: number,
    storeItemCategory: StoreItemCategory,
    stock?: number,
    cdnHash: string
}

export interface AddStoreItemInputModel {
    name: string,
    description: string,
    cost: number,
    storeItemCategory: StoreItemCategory,
    employerId?: string,
    stock?: number,
    image?: File
}

export interface EditStoreItemInputModel {
    id: string
    name: string,
    description: string,
    cost: number,
    image?: File,
    storeItemCategory: StoreItemCategory,
    employerId?: string,
    stock?: number,
    cdnHash: string
}

export interface StoreItemModel {
    id: string,
    name: string,
    description: string,
    cost: number,
    storeItemCategory: StoreItemCategory,
    stock?: number,
    cdnHash: string
}

export const useStore = () => {
    const { callApi } = useApi();

    const allStoreItems = async () => {
        const response = await callApi.current<StoreItemPlainModel[]>(
            '/storeitem'
        );
        return response;
    };

    const allEmployerStoreItems = async (employerId: string) => {
        const response = await callApi.current<StoreItemPlainModel[]>(
            `/storeitem/employer/${employerId}`
        );
        return response;
    };

    const getStoreItem = async (storeItemId: string) => {
        const response = await callApi.current<StoreItemModel>(
            `/storeitem/${storeItemId}`
        );
        return response;
    };

    const addStoreItem = async (newStoreItem: AddStoreItemInputModel) => {
        let formData = new FormData();
        formData = appendObject(newStoreItem);
        if (newStoreItem.image !== undefined) {
            formData.append('image', newStoreItem.image);
        }
        formData.append('cost', formatNumber(newStoreItem.cost));

        const response = await callApi.current(
            '/storeitem',
            {
                method: 'POST',
                body: formData
            }
        );
        return response;
    };

    const editStoreItem = async (changedStoreItem: EditStoreItemInputModel) => {
        let formData = new FormData();
        formData = appendObject(changedStoreItem);
        if (changedStoreItem.image === undefined) {
            formData.append('imageCdnHash', changedStoreItem.cdnHash);
        } else {
            formData.append('image', changedStoreItem.image);
        }
        formData.append('cost', formatNumber(changedStoreItem.cost));

        const response = await callApi.current(
            '/storeitem',
            {
                method: 'PUT',
                body: formData
            }
        );
        return response;
    };

    const removeStoreItem = async (storeItemId: string) => {
        const response = await callApi.current(
            `/storeitem/${storeItemId}`,
            {
                method: 'DELETE'
            }
        );
        return response;
    };

    return {
        allStoreItems,
        allEmployerStoreItems,
        getStoreItem,
        addStoreItem,
        editStoreItem,
        removeStoreItem
    };
};