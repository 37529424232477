import { MeasurableType } from 'hooks/challenge';
import { ChallengeMeasurementType, ChallengeMeasurementUnitType, ActivityAllType, ChallengeThemeType, ActivityCountType, ActivityType, GoalType, ChallengeCompletionPeriodType } from 'hooks/challengeTemplate';

export interface ChallengeDefaultThemeValue {
    measurementType: ChallengeMeasurementType;
    measurementUnit: ChallengeMeasurementUnitType;
    activity: ActivityAllType;
}

export const getDefaultValueByTheme = (theme: ChallengeThemeType) => {
    switch (theme) {
        default:
        case ChallengeThemeType.Movement: {
            return {
                measurementType: ChallengeMeasurementType.Distance,
                measurementUnit: ChallengeMeasurementUnitType.Meter,
                activity: ActivityAllType.Walk
            };
        }
        case ChallengeThemeType.Nutrition: {
            return {
                measurementType: ChallengeMeasurementType.Weight,
                measurementUnit: ChallengeMeasurementUnitType.Gram,
                activity: ActivityAllType.FoodAndDrinks
            };
        }
        case ChallengeThemeType.Relaxation: {
            return {
                measurementType: ChallengeMeasurementType.Duration,
                measurementUnit: ChallengeMeasurementUnitType.Minutes,
                activity: ActivityAllType.Meditation
            };
        }
    }
};

export const getDefaultMeasurementUnitByMeasurement = (measurement: ChallengeMeasurementType) => {
    switch (measurement) {
        case ChallengeMeasurementType.Distance:
            return ChallengeMeasurementUnitType.Meter;
        case ChallengeMeasurementType.Duration:
            return ChallengeMeasurementUnitType.Minutes;
        case ChallengeMeasurementType.Volume:
            return ChallengeMeasurementUnitType.Mililiter;
        case ChallengeMeasurementType.Weight:
            return ChallengeMeasurementUnitType.Gram;
        case ChallengeMeasurementType.Piece:
            return ChallengeMeasurementUnitType.Fruit;
        default:
        case ChallengeMeasurementType.Amount:
            return ChallengeMeasurementUnitType.Steps;
    }
};

export const getMeasurementUnitByMeasurement = (measurement: ChallengeMeasurementType) => {
    switch (measurement) {
        case ChallengeMeasurementType.Distance:
            return [ChallengeMeasurementUnitType.Kilometer, ChallengeMeasurementUnitType.Meter];
        case ChallengeMeasurementType.Duration:
            return [ChallengeMeasurementUnitType.Minutes, ChallengeMeasurementUnitType.Hour];
        case ChallengeMeasurementType.Volume:
            return [ChallengeMeasurementUnitType.Mililiter, ChallengeMeasurementUnitType.Centiliter, ChallengeMeasurementUnitType.Deciliter, ChallengeMeasurementUnitType.Liter];
        case ChallengeMeasurementType.Weight:
            return [ChallengeMeasurementUnitType.Gram, ChallengeMeasurementUnitType.Pound, ChallengeMeasurementUnitType.Kilogram];
        case ChallengeMeasurementType.Piece:
            return [ChallengeMeasurementUnitType.Fruit];
        default:
        case ChallengeMeasurementType.Amount:
            return [ChallengeMeasurementUnitType.Steps, ChallengeMeasurementUnitType.HeartRhythm];
    }
};

export const getMeasurementByTheme = (theme: ChallengeThemeType) => {
    switch (theme) {
        case ChallengeThemeType.Movement: {
            return [ChallengeMeasurementType.Amount, ChallengeMeasurementType.Distance, ChallengeMeasurementType.Duration];
        }
        case ChallengeThemeType.Nutrition: {
            return [ChallengeMeasurementType.Volume, ChallengeMeasurementType.Piece, ChallengeMeasurementType.Amount, ChallengeMeasurementType.Weight];
        }
        case ChallengeThemeType.Relaxation: {
            return [ChallengeMeasurementType.Duration];
        }
        default: {
            return [ChallengeMeasurementType.Duration];
        }
    }
};

export const getActivityByTheme = (theme: ChallengeThemeType) => {
    switch (theme) {
        case ChallengeThemeType.Movement: {
            return [
                ActivityAllType.Ride,
                ActivityAllType.Run,
                ActivityAllType.Swim,
                ActivityAllType.Walk,
                ActivityAllType.GymVisits
            ];
        }
        case ChallengeThemeType.Nutrition: {
            return [ActivityAllType.FoodAndDrinks];
        }
        case ChallengeThemeType.Relaxation: {
            return [ActivityAllType.Meditation];
        }
        default: {
            return [ActivityAllType.Unknown];
        }
    }
};

export const getActivityCountTypeByGoalType = (goalType: GoalType) => {
    switch (goalType) {
        case GoalType.GymVisitsPerDayInPeriod:
        case GoalType.StepsPerDayInPeriod:
        case GoalType.ExternalDistanceInPeriod:
            return ActivityCountType.PerDay;
        case GoalType.GymVisitsPerWeekInPeriod:
        case GoalType.StepsPerWeekInPeriod:
        case GoalType.ExternalDistancePerWeekInPeriod:
            return ActivityCountType.PerWeek;
        case GoalType.TotalGymVisitsInPeriod:
        case GoalType.TotalStepsInPeriod:
        case GoalType.TotalExternalDistanceInPeriod:
            return ActivityCountType.Total;
    }
};

export const getIconByActivityType = (activityType: ActivityType) => {
    switch (activityType) {
        case ActivityType.GymVisits:
            return 'qr'; //TODO needs icons
        case ActivityType.Run:
            return 'running';
        case ActivityType.Walk:
            return 'walking';
        case ActivityType.Ride:
            return 'cycling';
        case ActivityType.Swim:
            return 'swimming';
        default:
            return 'question';
    }
};

export const getColorByMeasurableType = (measurableType: MeasurableType, externalActivityType?: ActivityType) => {
    switch (measurableType) {
        case MeasurableType.Steps:
            return 'pink';
        case MeasurableType.GymVisits:
            return 'blue';
        case MeasurableType.ExternalDistance:
            switch (externalActivityType) {
                case ActivityType.Run:
                case ActivityType.Walk:
                case ActivityType.Ride:
                case ActivityType.Swim:
                    return 'babyBlue'; //TODO meer kleuren
                default:
                    return 'yellow';
            }
        default:
            return 'yellow';
    }
};

export const getColorByActivityType = (activity: ActivityType) => {
    switch (activity) {
        case ActivityType.Walk:
            return 'pink';
        case ActivityType.GymVisits:
            return 'blue';
        case ActivityType.Run:
        case ActivityType.Swim:
        case ActivityType.Ride:
            return 'babyBlue'; //TODO meer kleuren
        default:
            return 'yellow';
    }
};

//TODO translate. Kan dit in een helper?
export const getMeasurementLabelByMeasurableType = (measurableType: MeasurableType, externalActivityType?: ActivityType) => {
    switch (measurableType) {
        case MeasurableType.Steps:
            return 'Stappen';
        case MeasurableType.GymVisits:
            return 'Sportschool bezoeken';
        case MeasurableType.ExternalDistance:
            return 'Km';
        default:
            return '-';
    }
};

export const getIconByMeasurableType = (measurableType: MeasurableType, externalActivityType?: ActivityType) => {
    switch (measurableType) {
        case MeasurableType.Steps:
            return 'running';
        case MeasurableType.GymVisits:
            return 'qr'; //TODO needs icons
        case MeasurableType.ExternalDistance:
            switch (externalActivityType) {
                case ActivityType.Run:
                case ActivityType.Walk:
                    return 'running';
                case ActivityType.Ride:
                case ActivityType.Swim:
                    return 'swimming';
                default:
                    return 'question';
            }
        default:
            return 'question';
    }
};

export const getDaysOfChallenge = (completionPeriod: ChallengeCompletionPeriodType, amount: number) => {
    switch (completionPeriod) {
        default:
        case ChallengeCompletionPeriodType.PerDay:
            return amount;
        case ChallengeCompletionPeriodType.PerWeek:
            return amount * 7;
        case ChallengeCompletionPeriodType.PerMonth:
            return amount * 30;
    }
};