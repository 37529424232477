import React, { useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import theme from 'static/theme';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormDropFile } from 'components/atoms/form';
import { Button } from 'components/atoms/button';
import {
    EmployeeVerificationModel,
    InviteEmployerEmployeeModel,
    ReadImportEmployeeExcelInputModel,
    useEmployerEmployees
} from 'hooks/employerEmployees';
import { toast } from 'react-toastify';
import { toFormikErrors } from 'utils/errorhelper';
import { saveAs } from 'file-saver';
import Table from 'components/molecules/table/Table';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import Group from 'components/molecules/Group';
import Margin from 'components/atoms/Margin';

interface ExcelInputProps {
    onSubmit: (models: InviteEmployerEmployeeModel[]) => void;
}

const initialExcelValues: ReadImportEmployeeExcelInputModel = {
    file: undefined
};

const useExcelInputModal = (excelInputProps: ExcelInputProps): [() => void, () => void, boolean] => {
    const { t } = useTranslation('excelModal');
    const [excelEmployees, setExcelExployees] = useState<EmployeeVerificationModel[]>([]);
    const { getExcelExample, verifyEmailKnownExcel } = useEmployerEmployees();

    const downloadImportTemplate = async () => {
        const response = await getExcelExample();

        if (response.ok) {
            response.blob().then((blob) => {
                saveAs(blob, 'import_users_template.xlsx');
            });
        } else if (response.errors) {
            toast.error(response.errors);
        }
    };

    const handleSubmit = async (values: ReadImportEmployeeExcelInputModel, helpers: FormikHelpers<ReadImportEmployeeExcelInputModel>) => {
        if (values === undefined) {
            toast.error(t('noFile'));
            return;
        }

        helpers.setSubmitting(true);
        const response = await verifyEmailKnownExcel(values);

        if (response && response.ok && response.data) {
            setExcelExployees(response.data);
        } else if (response && response.errors) {
            const errors = toFormikErrors<ReadImportEmployeeExcelInputModel>(response.errors);
            helpers.setErrors(errors);
            toast.error(response.errors[0].message);
        }

        helpers.setSubmitting(false);
    };

    const handleInviteByExcel = () => {
        excelInputProps.onSubmit(excelEmployees);
    };

    const columns: Column<EmployeeVerificationModel>[] = [
        {
            Header: t('email').toString(),
            accessor: (employee) => employee.email
        },
        {
            Header: t('isKnown').toString(),
            accessor: (employee) => (employee.verified ? t('common:yes') : t('common:no'))
        }
    ];

    const onHide = () => {
        setExcelExployees([]);
        hide();
    };

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal onHide={onHide} inProp={inProp} onExited={onExited} title={t('title')} size="large">
                <div style={{ marginTop: -theme.card.padding.horizontal }}>
                    <Formik initialValues={initialExcelValues} onSubmit={handleSubmit}>
                        {({ isSubmitting }) => (
                            <Form>
                                <Margin top={1}>
                                    <FormGroup>
                                        <FormikFormDropFile name="file" label={t('file')} />
                                    </FormGroup>
                                </Margin>
                                <FormGroup noMargin={excelEmployees.length === 0}>
                                    <Group spaceBetween>
                                        <Button type="button" onClick={downloadImportTemplate}>
                                            {t('downloadExcel')}
                                        </Button>
                                        <Button loading={isSubmitting} type="submit">
                                            {t('submit')}
                                        </Button>
                                    </Group>
                                </FormGroup>
                                {excelEmployees.length > 0 && (
                                    <>
                                        <FormGroup label={t('userList')}>
                                            <Table<EmployeeVerificationModel> data={excelEmployees} columns={columns} />
                                        </FormGroup>
                                        <Button type="button" onClick={handleInviteByExcel} brand="yellow">
                                            {t('sendInvites')}
                                        </Button>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        ),
        [excelEmployees]
    );

    return [show, hide, isShowing];
};
export default useExcelInputModal;
