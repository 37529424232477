import React, { FC } from 'react';
import { Wrapper, Image, Body, ProductName, ProductNumber, Specs } from './styles';

export interface ProductProps {
    big?: boolean;
    // Don't use DB modal here!!
    name: string;
    image?: string;
    specs?: string[];
}

const Product: FC<ProductProps> = ({ big = false, name, image, specs }) => {
    return (
        <Wrapper big={big}>
            <Image big={big}>{image ? <img alt={name} src={image} /> : <span>?</span>}</Image>
            <Body>
                <ProductName>{name}</ProductName>
                <ProductNumber>#123</ProductNumber>
                {specs && (
                    <Specs>
                        {specs.map((item, index) => (
                            <span key={index}>{item}</span>
                        ))}
                    </Specs>
                )}
            </Body>
        </Wrapper>
    );
};

export default Product;
