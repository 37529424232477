import { css } from '@emotion/react';
import theme from 'static/theme';

export const styles = css`
.react-currency-input-field {
    color: 'red';
    width: '100%';
    height: ${theme.form.height}rem;
    border: 1px solid ${theme.greys[50]};
    border-radius: ${theme.form.height / 2}rem;
    padding: 0 ${theme.form.padding.horizontal}px;
    font-size: ${theme.form.fontSize}rem;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${theme.greys[50]};
    }

    &:hover {
        border-color: ${theme.greys[100]};
    }

    ::placeholder {
        color: ${theme.greys[300]};
    }
}`;
