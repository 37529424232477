import { useField } from 'formik';
import React from 'react';
import FormControlSelect, { FormControlSelectProps } from '..';

interface FormikFormControlSelectProps<T extends number | string> extends FormControlSelectProps<T> {
    nameControl: string;
    nameSelect: string;
    afterChange?: () => void;
}

const FormikFormControlSelect = <T extends number | string>({ nameControl, nameSelect, afterChange, ...props }: FormikFormControlSelectProps<T>) => {
    const fieldControl = useField(nameControl);
    const fieldSelect = useField(nameSelect);

    // Methods.
    const handleOnSelectChange = (value: T) => {
        fieldSelect[2].setValue(value);
        afterChange && afterChange();
    };

    const handleOnControlhange = (value: string) => {
        fieldControl[2].setValue(value);
        afterChange && afterChange();
    };

    // Render.
    const error = !!(fieldControl[1].touched && fieldControl[1].error) ? fieldControl[1].error : undefined;

    return (
        <FormControlSelect
            {...props}
            valueControl={fieldControl[0].value}
            valueSelect={fieldSelect[1].value}
            onChangeConrol={handleOnControlhange}
            onChangeSelect={handleOnSelectChange}
            error={error}
        />
    );
};

export default FormikFormControlSelect;
