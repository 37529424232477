import React, { FC, useEffect, useState } from 'react';
import { FormikFormStatistic, FormGroup } from 'components/atoms/form';
import KeyValueLine from 'components/atoms/KeyValueLine';
import { useFormikContext } from 'formik';
import { PointsValues } from '..';
import { xpToEuro } from 'utils/xpToCurrencyHelper';

interface Step1Props {
    currentPoints: number;
}

const Step1: FC<Step1Props> = ({ currentPoints }) => {

    const { values } = useFormikContext<PointsValues>();

    const [points, setPoints] = useState<number>(0);

    useEffect(() => {
        setPoints(values.points);
    }, [values]);

    const calculateNewPoints = () => {
        const amount = Number(points) + Number(currentPoints);
        return amount + 'XP';
    };

    return (
        <>
            <FormGroup>
                <FormikFormStatistic name="points" placeholder="1000" label="Hoeveel XP wil je kopen?" />
            </FormGroup>
            <FormGroup>
                <KeyValueLine title="Huidige punten" value={`${currentPoints}xp`} />
                <KeyValueLine title="Kosten" value={`${xpToEuro(points)}`} />
                <KeyValueLine last title="Aantal XP na deze aankoop" value={calculateNewPoints()} highlightValue />
            </FormGroup>
        </>
    );
};

export default Step1;