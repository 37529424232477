import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { lighten, rgba } from 'polished';
import { RoundButtonProps } from './';

const lightenAmount = {
    hover: 0.1
};

export const Button = styled.button<RoundButtonProps>`
    border: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ theme, brand, disabled, noFocus }) => {
        const color = theme.brand[brand ?? 'blue'];

        return css`
            background-color: ${disabled ? theme.colors.medium : color};

            &:focus {
                outline: none;
                box-shadow: ${noFocus ? 'none' : `0 0 0 0.2rem ${rgba(color, 0.5)}`};
            }

            &:hover {
                cursor: pointer;
                background-color: ${disabled ? theme.colors.medium : lighten(lightenAmount.hover, color)};
            }
        `;
    }}

    ${({ theme, white, noFocus }) =>
        white &&
        css`
            background-color: white;

            &:hover {
                background-color: ${theme.greys[50]};
            }

            &:focus {
                outline: none;
                box-shadow: ${noFocus ? 'none' : `0 0 0 0.2rem $${theme.greys[100]}`};
            }
        `}
`;
