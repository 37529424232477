import { useApi } from 'hooks/api';
import { CompleteInvitationModel, InvitationModel } from 'hooks/invitation';


export interface SupplierModel {
    id: string,
    email: string,
    firstName: string,
    insertion?: string,
    lastName: string,
    address: string,
    houseNumber: string,
    city: string,
    country: string,
    postalCode: string,
    phoneNumber: string,
    kvkNumber: string,
    btwNumber: number,
    bankAccountNumber: string,
    isActive: boolean,
    contactPerson: SupplierContactPersonModel,
    supplierName: string;
}

export interface AddSupplierInputModel {
    email: string,
    password: string,
    firstName: string,
    insertion?: string,
    lastName: string,
    address: string,
    houseNumber: string,
    city: string,
    country: string,
    postalCode: string,
    phoneNumber: string,
    kvkNumber: string,
    btwNumber: string,
    bankAccountNumber: string
    contactPerson: AddSupplierContactPersonInputModel,
    supplierName: string
}

export interface AddSupplierContactPersonInputModel {
    firstName: string;
    insertion?: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface EditSupplierInputModel {
    id: string,
    email: string;
    firstName: string,
    insertion?: string,
    lastName: string,
    address: string,
    houseNumber: string,
    city: string,
    country: string,
    postalCode: string,
    phoneNumber: string,
    kvkNumber: string,
    btwNumber: number,
    bankAccountNumber: string,
    supplierName: string,
    contactPerson: EditSupplierContactPersonInputModel
}

export interface EditSupplierContactPersonInputModel {
    id?: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface SupplierContactPersonModel {
    id?: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    fullName: string;
    email: string;
    phoneNumber: string;
}

export const useSuppliers = () => {
    const { callApi } = useApi();

    const allSuppliers = async () => {
        const response = await callApi.current<SupplierModel[]>('/Supplier/All');

        return response;
    };

    const addSupplier = async (addSupplierInputModel: AddSupplierInputModel) => {
        const response = await callApi.current(
            '/Supplier/Add',
            {
                method: 'POST',
                body: JSON.stringify(addSupplierInputModel)
            }
        );
        return response;
    };

    const editSupplier = async (editSupplierInputModel: EditSupplierInputModel) => {
        const response = await callApi.current(
            '/Supplier/Edit',
            {
                method: 'PUT',
                body: JSON.stringify(editSupplierInputModel)
            }
        );
        return response;
    };

    const reEnableSupplier = async (supplierId: string) => {
        const response = await callApi.current(
            `/Supplier/ReEnable/${supplierId}`,
            {
                method: 'PUT'
            }
        );
        return response;
    };

    const deleteSupplier = async (supplierId: string) => {
        const response = await callApi.current(
            `/Supplier/${supplierId}`,
            {
                method: 'DELETE'
            }
        );
        return response;
    };

    const denySupplier = async (code: string) => {
        const response = await callApi.current(
            `/Supplier/${code}/Deny`,
            {
                method: 'POST',
            }
        );
        return response;
    };

    const acceptSupplier = async (code: string) => {
        const response = await callApi.current(
            `/Supplier/${code}/Accept`,
            {
                method: 'POST',
            }
        );
        return response;
    };

    const invitationSupplierInfo = async (code: string) => {
        const response = await callApi.current<InvitationModel>(`/Supplier/${code}`);
        return response;
    };

    const completeInvitationSupplier = async (completeContentCreatorRegisterModel: CompleteInvitationModel) => {
        const response = await callApi.current('/Supplier/Register/Complete',
            {
                method: 'POST',
                body: JSON.stringify(completeContentCreatorRegisterModel)
            }
        );
        return response;
    };


    return {
        allSuppliers,
        addSupplier,
        editSupplier,
        reEnableSupplier,
        completeInvitationSupplier,
        invitationSupplierInfo,
        denySupplier,
        acceptSupplier,
        deleteSupplier
    };
};