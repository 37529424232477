import React from 'react';
import FormItemSearch, { FormCustomerSearchProps } from '..';
import { useField } from 'formik';
import InvalidFeedback from '../../InvalidFeedback';

interface FormikFormCustomerSearchProps<T> extends FormCustomerSearchProps<T> {
    name: string;
}

const FormikFormItemSearch = <T extends { id: string }>({ name, ...props }: FormikFormCustomerSearchProps<T>) => {
    const [field, meta, helpers] = useField<string>(name);

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return (
        <>
            <FormItemSearch value={field.value} onItemClick={helpers.setValue} {...props} />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormikFormItemSearch;

