import React, { FC, useState, useEffect } from 'react';
import Dropdown from '../Dropdown';
import { useUser } from 'hooks/user';
import { useSession } from 'contexts/sessionContext';
import { defaultAreaRoute } from 'utils/areaHelper';
import { navigate } from '@reach/router';
import { EmployerPlainModel, useEmployers } from 'hooks/employers';

export interface EmployerDropdownProps { }

const EmployerDropdown: FC<EmployerDropdownProps> = () => {
    const [employers, setEmployers] = useState<EmployerPlainModel[]>([]);

    const { session } = useSession();
    const { switchActiveEmployer } = useUser();
    const { allEmployers } = useEmployers();

    // Life cycle
    useEffect(() => {
        getEmployers();
    }, []);

    // Methods
    const handleOnSubItemClick = async (value: string) => {
        const response = await switchActiveEmployer(value);

        if (response.ok && session?.role && session.activeEnvironment) {
            const route = defaultAreaRoute(session.role, session.activeEnvironment);
            navigate(route);
            window.location.reload();
        } else {
            // TODO: set prev value or something.
        }
    };

    const getEmployers = async () => {
        const response = await allEmployers();

        if (response.ok && response.data) {
            setEmployers(response.data);
        }
    };

    // Render.
    const activeEmployerId = session?.activeEmployerId;
    const selected = employers.find((item) => item.id === activeEmployerId);

    return (
        <Dropdown
            items={employers.map((employer) => ({
                title: employer.name,
                value: employer.id
            }))}
            onSubItemClick={(value) => handleOnSubItemClick(value as string)}
        >
            {selected?.name}
        </Dropdown>
    );
};

export default EmployerDropdown;
