import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import { FormGroup, FormikFormControl, FormikFormSelect } from 'components/atoms/form';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button } from 'components/atoms/button';
import { AddressType } from 'hooks/employers';

interface AddressValues {
    id?: string;
    addressType: AddressType;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    country: string;
}

const useAddAddressModal = (submit: (addressValues: AddressValues, index?: number) => void): [(index?: number, addressValues?: AddressValues) => void, () => void, boolean] => {

    const [index, setIndex] = useState<number>();
    const [addressValues, setAddressValues] = useState<AddressValues | undefined>();

    // Methods.
    const handleOnSubmit = (values: AddressValues, helpers: FormikHelpers<AddressValues>) => {
        submit(values, index);
        hide();
    };

    const initialValues: AddressValues = {
        id: addressValues?.id,
        street: addressValues?.street ?? '',
        houseNumber: addressValues?.houseNumber ?? '',
        city: addressValues?.city ?? '',
        addressType: addressValues?.addressType ?? AddressType.billing,
        postalCode: addressValues?.postalCode ?? '',
        country: addressValues?.country ?? ''
    };

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal onHide={hide} inProp={inProp} onExited={onExited} title="Adres toevoegen">
                <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
                    <Form>
                        <FormGroup label="Type adres">
                            <FormikFormSelect
                                name="addressType"
                                options={[
                                    { title: 'Factuuradres', value: AddressType.billing },
                                    { title: 'Bezoekadres', value: AddressType.visiting }
                                ]}
                            />
                        </FormGroup>
                        <FormGroup label="Straat">
                            <FormikFormControl name="street" />
                        </FormGroup>
                        <FormGroup label="Huisnummer">
                            <FormikFormControl name="houseNumber" />
                        </FormGroup>
                        <FormGroup label="Postcode">
                            <FormikFormControl name="postalCode" />
                        </FormGroup>
                        <FormGroup label="Woonplaats">
                            <FormikFormControl name="city" />
                        </FormGroup>
                        <FormGroup label="Land">
                            <FormikFormControl name="country" />
                        </FormGroup>
                        <Button type="submit">
                            Toevoegen
                        </Button>
                    </Form>
                </Formik>
            </Modal>
        ),
        [addressValues]
    );

    const showModal = useCallback((index?: number, addressValues?: AddressValues) => {
        setAddressValues(addressValues);
        setIndex(index);
        show();
    }, [show]);

    return [showModal, hide, isShowing];
};

export default useAddAddressModal;
