import Spinner from 'components/old/Spinner';
import React, { FC } from 'react';
import * as styles from './styles';

interface LoadingProps {
    fullScreen?: boolean;
}

const Loading: FC<LoadingProps> = (props) => {

    const {
        fullScreen
    } = props;

    return (
        <div css={[styles.wrapper, fullScreen ? styles.fixed : null]}>
            {/* <Image css={styles.logo} responsive src={logo} /> */}
            <Spinner size={50} />
        </div>
    );
};

export default Loading;
