import { useField } from 'formik';
import React, { FC, useState } from 'react';
import FormDate from '..';

interface FormikFormDateProps {
    name: string;
    afterChange?: (date: Date) => void;
    onValidate?: (date: Date, name: string) => string | undefined;
}

const FormikFormDate: FC<FormikFormDateProps> = ({ name, afterChange, onValidate, ...props }) => {
    const [field, meta, helpers] = useField(name);
    // Render.
    const [error, setError] = useState<string | undefined>(!!(meta.touched && meta.error) ? meta.error : undefined);

    // Methods.
    const handleOnChange = (date: Date) => {
        helpers.setValue(date);
        helpers.setTouched(true);
        setError(onValidate ? onValidate(date, name) : undefined);
        afterChange && afterChange(date);
    };

    return <FormDate name={name} selected={field.value} {...props} onChange={handleOnChange} error={error} />;
};

export default FormikFormDate;
