import React, { FC } from 'react';
import * as styles from './styles';
import theme from 'static/theme';
interface SpinnerProps {
    color?: string;
    size?: number;
}

const Spinner: FC<SpinnerProps> = (props) => {

    const { color = theme.colors.medium, size = 16 } = props;

    return (
        <div
            css={[styles.spinner, { borderColor: color, width: `${size}px`, height: `${size}px` }]}
        />
    );
};

export default Spinner;
