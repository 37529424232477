import React, { FC } from 'react';
import DropdownItem, { DropdownItemProps } from '../DropdownItem';
import { useHover, useLayer } from 'react-laag';
import DropdownMenu from '../../../molecules/dropdown/DropdownMenu';
import { AnimatePresence } from 'framer-motion';

export interface NestedDropdownItemProps extends DropdownItemProps {
    onSubItemClick?: (value: string | number | boolean) => void;
    onSubSubItemClick?: (value: string | number | boolean) => void;
    items: DropdownItemProps[];
}

const NestedDropdownItem: FC<NestedDropdownItemProps> = ({ items, onSubItemClick, onSubSubItemClick, ...props }) => {
    const [isOpen, hoverProps, close] = useHover({
        delayEnter: 0,
        delayLeave: 100
    });

    const { renderLayer, triggerProps, layerProps } = useLayer({
        overflowContainer: true,
        auto: true,
        snap: true,
        placement: 'right-start',
        possiblePlacements: ['right-start', 'left-start'],
        triggerOffset: 0,
        containerOffset: 16,
        arrowOffset: 8,
        isOpen,

        // this is an important one: when the root-menu closes, we want all nested
        // menu's to close as well. Therefore, we can utilize this `onParentClose` props
        // to instruct `useHover` in this case to force-close possible nested menu's
        onParentClose: close
    });

    // Render.
    return (
        <DropdownItem {...props} {...hoverProps} {...triggerProps} ref={triggerProps.ref} onSubItemClick={onSubItemClick} nested>
            {renderLayer(
                <AnimatePresence>
                    {isOpen && (
                        <DropdownMenu
                            close={close}
                            items={items}
                            {...hoverProps}
                            {...layerProps}
                            ref={layerProps.ref}
                            onSubItemClick={onSubSubItemClick}
                        />
                    )}
                </AnimatePresence>
            )}
        </DropdownItem>
    );
};

export default NestedDropdownItem;
