import { Button } from 'components/atoms/button';
import { StepProps } from 'components/atoms/Step';
import Group from 'components/molecules/Group';
import Stepper from 'components/molecules/Stepper';
import { Form, Formik, FormikHelpers, FormikTouched } from 'formik';
import React, { ReactNode, useState } from 'react';
import { BaseSchema } from 'yup';
import { Wizard as Wrapper } from './styles';

interface Step<T> extends StepProps {
    child: ReactNode;
    onSubmit?: (values: T, helpers: FormikHelpers<T>) => Promise<boolean>;
    validationSchema?: BaseSchema;
}

export interface WizardProps<T> {
    steps: Step<T>[];
    hideStepper?: boolean;
    initialValues: T;
    initialStep?: number;
    onSubmit: (values: T, helpers: FormikHelpers<T>) => void;
    onSubmitLabel?: string;
    optionalButtonOnClick?: () => void;
    optionalButtonLabel?: string;
}

export function Wizard<T>({ steps: initialSteps, hideStepper, initialValues, onSubmit, onSubmitLabel, initialStep = 0, optionalButtonOnClick, optionalButtonLabel }: WizardProps<T>) {
    const [number, setNumber] = useState<number>(initialStep);
    const [snapshot, setSnapshot] = useState<T>(initialValues);

    // Wizard's methods.
    const next = (values: T, setTouched: (touched: FormikTouched<T>, shouldValidate?: boolean) => void) => {
        handleStepChange(values, Math.min(number + 1, totalSteps - 1), setTouched);
    };

    const previous = (values: T, setTouched: (touched: FormikTouched<T>, shouldValidate?: boolean) => void) => {
        handleStepChange(values, Math.max(number - 1, 0), setTouched);
    };

    const handleStepChange = (values: T, number: number, setTouched: (touched: FormikTouched<T>, shouldValidate?: boolean) => void) => {
        setTouched({}, false);
        setNumber(number);
        setSnapshot(values);
    };

    const getPreviousStep = (number: number): Step<T> => {
        return initialSteps[Math.max(number - 1, 0)];
    };

    const handleSubmit = async (values: T, helpers: FormikHelpers<T>) => {
        if (step.onSubmit) {
            const result = await step.onSubmit(values, helpers);

            if (!result) {
                return;
            }
        }

        if (lastStep) {
            return onSubmit(values, helpers);
        } else {
            next(values, helpers.setTouched);
        }
    };

    // Render.
    const step = initialSteps[number];
    const totalSteps = initialSteps.length;
    const firstStep = number === 0;
    const lastStep = number === totalSteps - 1;

    return (
        <Wrapper>
            <Formik<T> initialValues={snapshot} onSubmit={handleSubmit} validationSchema={step.validationSchema} enableReinitialize>
                {({ values, setTouched, handleSubmit, isValid, isSubmitting }) => {
                    const steps = initialSteps.map((step, index) => ({
                        ...step,
                        active: index === number,
                        disabled: !getPreviousStep(index).validationSchema?.isValidSync(values),
                        onClick: () => handleStepChange(values, index, setTouched)
                    }));

                    return (
                        <Form>
                            {
                                !hideStepper &&
                                <Stepper steps={steps} />
                            }
                            {step.child}
                            <Group spaceBetween right>
                                {optionalButtonOnClick && optionalButtonLabel &&
                                    <Button type="button" onClick={optionalButtonOnClick}>
                                        {optionalButtonLabel}
                                    </Button>
                                }
                                {!firstStep && (
                                    <Button type="button" onClick={() => previous(values, setTouched)} brand="yellow">
                                        Vorige stap
                                    </Button>
                                )}
                                <Button type="submit" disabled={isSubmitting || !isValid} loading={isSubmitting} brand="yellow">
                                    {lastStep ? onSubmitLabel || 'Bevestigen' : 'Volgende stap'}
                                </Button>
                            </Group>
                        </Form>
                    );
                }}
            </Formik>
        </Wrapper>
    );
}

export default Wizard;
