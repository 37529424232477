import Notification from 'components/atoms/Notification';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { Tab, Tabs as Wrapper } from './styles';

export interface TabsProps {
    tabs: Tab[];
    current: string | number;
    setCurrent: Dispatch<SetStateAction<number | string>>;
}

export interface Tab {
    label: string;
    value: string | number;
    amount?: number;
}

const Tabs: FC<TabsProps> = ({ tabs, current, setCurrent }) => {
    return (
        <Wrapper>
            {tabs.map((tab) =>
                <Tab key={tab.value} onClick={() => setCurrent(tab.value)} active={current === tab.value}>
                    {tab.label}
                    {tab.amount !== undefined &&
                        <Notification disabled={current !== tab.value}>{tab.amount}</Notification>
                    }
                </Tab>
            )}
        </Wrapper>
    );
};

export default Tabs;
