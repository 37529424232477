import { useApi } from 'hooks/api';
import { useSession } from 'contexts/sessionContext';
import { buildUriWithParams } from 'utils/uriHelper';
import { SessionModel } from 'api';
import { navigate } from '@reach/router';

export interface InviteEmployerEmployeeModel {
    email: string;
}

export interface EmployerEmployeePlainModel {
    id: string;
    fullName: string;
    email: string;
}

export interface EmployerEmployeeInvitationModel {
    id: string;
    code: string;
    emailInvitee: string;
    invitedBy: EmployerEmployeePlainModel;
    invitedOn: string;
    isAlreadyKnown: boolean;
    acceptanceName: string;
    tfaEnabled: boolean;
}

export interface AllEmployerEmployeesQueryOptions {
    completedRegistration?: boolean;
    employerId?: string;
    locationId?: string;
    acceptedEmployerInvite?: boolean;
}

export interface AllEmployerEmployeeInvitationsQueryOptions {
    completedRegistration?: boolean;
}

export interface CompleteEmployerEmployeeRegistrationModel {
    email: string;
    code: string;
    tfaCode: string;
    firstName: string;
    insertion: string;
    lastName: string;
    password: string;
    confirmPassword: string;
}

export interface AcceptOrDenyEmployerInviteModel {
    accept: boolean;
    code: string;
}

export interface EmployeeVerificationModel {
    email: string;
    verified: boolean;
}

export interface ReadImportEmployeeExcelInputModel {
    file?: File;
}

export const useEmployerEmployees = () => {
    const { callApi } = useApi();
    const { session, setSession } = useSession();

    const allEmployees = async (queryOptions?: AllEmployerEmployeesQueryOptions) => {
        const uri = buildUriWithParams('/EmployerEmployee', queryOptions);

        const response = await callApi.current<EmployerEmployeePlainModel[]>(uri);

        return response;
    };

    const getEmployee = async (id: string) => {
        const response = await callApi.current<EmployerEmployeePlainModel>(
            `/EmployerEmployee/${id}`,
            {
                method: 'GET'
            }
        );

        return response;
    };

    const getExcelExample = async () => {
        const response = await callApi.current(
            '/EmployerEmployeeInvitation/ExampleExcel'
        );
        return response;
    };

    const allEmployeeInvitations = async () => {
        const response = await callApi.current<EmployerEmployeeInvitationModel[]>(`/Employer/${session?.activeEmployerId ?? ''}/EmployeeInvitations`);

        return response;
    };

    const verifyEmailKnownExcel = async (excelFileModel: ReadImportEmployeeExcelInputModel) => {
        if (excelFileModel.file !== undefined) {
            const formData = new FormData();
            formData.append('file', excelFileModel.file);
            const response = await callApi.current<EmployeeVerificationModel[]>(
                `/Employer/${session?.activeEmployerId ?? ''}/VerifyEmailExcel`,
                {
                    method: 'POST',
                    body: formData
                });
            return response;
        }
    };

    const inviteExcel = async (inviteEmployeeModels: InviteEmployerEmployeeModel[]) => {
        const response = await callApi.current(
            `/Employer/${session?.activeEmployerId ?? ''}/InviteMultipleEmployees`,
            {
                method: 'POST',
                body: JSON.stringify(inviteEmployeeModels)
            }
        );
        return response;
    };

    const inviteEmployee = async (inviteEmployeeModel: InviteEmployerEmployeeModel) => {
        const response = await callApi.current(
            `/Employer/${session?.activeEmployerId ?? ''}/InviteEmployee`,
            {
                method: 'POST',
                body: JSON.stringify(inviteEmployeeModel)
            }
        );

        return response;
    };

    const cancelEmployeeInvitation = async (invitationId: string) => {
        const response = await callApi.current(
            `/EmployerEmployeeInvitation/${invitationId}`,
            {
                method: 'DELETE'
            }
        );

        return response;
    };

    const resendEmployeeInvitation = async (invitationId: string) => {
        const response = await callApi.current(
            `/EmployerEmployeeInvitation/${invitationId}/resend`,
            {
                method: 'POST'
            }
        );

        return response;
    };

    const sendRegistrationTfaCode = async (invitationCode: string) => {
        const response = await callApi.current(
            '/EmployerEmployee/sendRegistrationTfaCode',
            {
                method: 'POST',
                body: JSON.stringify({ code: invitationCode })
            }
        );

        return response;
    };

    const completeRegistration = async (completeAdminRegistrationModel: CompleteEmployerEmployeeRegistrationModel) => {
        const response = await callApi.current<SessionModel>(
            '/EmployerEmployee/CompleteRegistration',
            {
                method: 'POST',
                body: JSON.stringify(completeAdminRegistrationModel)
            }
        );

        if (response.ok && response.data) {
            const data = response.data;

            if (data.requiresVerification) {
                navigate('/account/tfa');
            } else {
                setSession(data.claims);
                navigate('/');
            }
        }

        return response;
    };

    const validateTfaCode = async (tfaCode: string, registrationCode: string) => {
        const response = await callApi.current(
            '/EmployerEmployee/ValidateTfa',
            {
                method: 'POST',
                body: JSON.stringify({
                    tfaCode,
                    code: registrationCode
                })
            }
        );

        return response;
    };

    const invitationInfo = async (registrationCode: string) => {
        const response = await callApi.current<EmployerEmployeeInvitationModel>(
            `/EmployerEmployeeInvitation/${registrationCode}`
        );

        return response;
    };

    const acceptOrDenyEmployerInvite = async (values: AcceptOrDenyEmployerInviteModel) => {
        const response = await callApi.current(
            `/EmployerEmployeeInvitation/${values.code}/${values.accept ? 'Accept' : 'Deny'}`,
            {
                method: 'POST'
            }
        );

        return response;
    };

    const removeEmployeeFromEmployer = async (userId: string) => {
        const response = await callApi.current(
            `/Employer/${session?.activeEmployerId ?? ''}/Employee`,
            {
                method: 'DELETE',
                body: JSON.stringify({ userId })
            }
        );

        return response;
    };

    return {
        allEmployees,
        getEmployee,
        allEmployeeInvitations,
        inviteEmployee,
        cancelEmployeeInvitation,
        resendEmployeeInvitation,
        sendRegistrationTfaCode,
        completeRegistration,
        validateTfaCode,
        invitationInfo,
        acceptOrDenyEmployerInvite,
        removeEmployeeFromEmployer,
        verifyEmailKnownExcel,
        inviteExcel,
        getExcelExample
    };
};
