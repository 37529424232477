import React, { useState } from 'react';
import useModal from '../modal';
import Panel from 'components/organisms/Panel';
import { Formik, FormikHelpers } from 'formik';
import Alert from 'components/molecules/Alert';
import { useTranslation } from 'react-i18next';
import { AddFitnessProductModel, BtwPercentageType, ProductDuration, ProductSalesUnitType, useFitnessProduct } from 'hooks/fitnessProducts';
import { useSession } from 'contexts/sessionContext';
import { toFormikErrors } from 'utils/errorhelper';
import AddSupplierProductForm from './components/AddSupplierProductForm';
import * as yup from 'yup';

const initialValues: AddFitnessProductModel = {
    name: '',
    numberType: '',
    purchasePrice: 0,
    btwPercentage: BtwPercentageType.Percent6,
    description: '',
    productSalesUnit: ProductSalesUnitType.Pieces,
    productDuration: ProductDuration.Months12,
    supplierId: '',
    images: []
};

const useAddSupplierProduct = (onRefresh: () => void): [() => void, () => void, boolean] => {
    const [isAdded, setIsAdded] = useState<boolean>(false);
    const { t } = useTranslation('templateFitnessProducts');
    const { addFitnessProduct } = useFitnessProduct();

    const { session } = useSession();

    const schemaValidatorObject = yup.object().shape({
        name: yup.string()
            .required(t('validation.required', { value: t('form.label.name') }))
            .max(128, t('validation.maxLength', { value: t('form.label.name'), max: '128' })),
        description: yup.string()
            .required(t('validation.required', { value: t('form.label.description') }))
            .max(256, t('validation.maxLength', { value: t('form.label.description'), max: '256' })),
        images: yup.array()
            .of(yup.object())
            .min(1, t('validation.images.required')),
        purchasePrice: yup.number()
            .required(t('validation.required', { value: t('form.label.price') }))
            .min(0.00, t('validation.minimumPrice')),
        numberType: yup.string()
            .required(t('validation.required', { value: t('form.label.typeNumber') }))
    });

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) =>
            !isAdded ? (
                <Formik<AddFitnessProductModel>
                    initialValues={initialValues}
                    onSubmit={handleOnSubmit}
                    validationSchema={schemaValidatorObject}
                    validateOnBlur={true}
                    validateOnChange={true}
                >
                    {({ handleSubmit, touched }) => (
                        <Panel
                            onHide={handleHide}
                            inProp={inProp}
                            onExited={onExited}
                            title={t('title')}
                            subtitle={t('form.add.title')}
                            buttons={[
                                { title: t('common:button.cancel'), onClick: hide, isOutline: true },
                                { title: t('common:button.add'), onClick: () => handleSubmit(), type: 'submit' }
                            ]}
                            onTouched={touched}
                        >
                            <AddSupplierProductForm mode={'add'} />
                        </Panel>
                    )}
                </Formik>
            ) : (
                <Panel
                    onHide={handleHide}
                    inProp={inProp}
                    onExited={onExited}
                    title={t('title')}
                    subtitle={t('form.add.title')}
                    buttons={[{ title: t('common:button.confirm'), onClick: handleHide }]}
                >
                    <Alert
                        title={t('confirm.title')}
                        description={t('confirm.description.add')}
                    />
                </Panel>
            ),
        [isAdded, initialValues]
    );

    // Methods.
    const handleHide = () => {
        hide();
        initialValues.images = [];
        setIsAdded(false);
    };

    const handleOnSubmit = async (values: AddFitnessProductModel, actions: FormikHelpers<AddFitnessProductModel>) => {
        if (session?.activeSupplierId === undefined) {
            return;
        }

        actions.setSubmitting(true);
        values.supplierId = session?.activeSupplierId;

        const response = await addFitnessProduct(values);

        if (response.ok) {
            setIsAdded(true);
            onRefresh();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            actions.setErrors(errors);
        }
        actions.setSubmitting(false);
    };

    return [show, handleHide, isShowing];
};

export default useAddSupplierProduct;

