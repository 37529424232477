import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { lighten, rgba } from 'polished';
import { ButtonProps } from './';
import Icon from 'components/atoms/Icon';

const lightenAmount = {
    disabled: 0.4,
    hover: 0.1
};

export const Button = styled.button<ButtonProps>`
    border: none;
    outline: none;
    height: 3.25rem;
    padding: 0 ${({ rounded }) => (rounded ? 24 : 16)}px;
    border-radius: ${({ theme, rounded }) => (rounded ? '3.5125rem' : `${theme.borderRadius.normal}px`)};
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: ${({ alignSelf }) => alignSelf};
    white-space: nowrap;

    ${({ theme, brand, disabled, noFocus }) => {
        const color = theme.brand[brand || 'blue'];

        return css`
            color: ${disabled ? rgba(theme.colors.black, 0.5) : theme.colors.white};
            background-color: ${disabled ? theme.colors.medium : color};

            &:focus {
                outline: none;
                box-shadow: ${noFocus ? 'none' : `0 0 0 0.2rem ${rgba(color, 0.5)}`};
            }

            &:hover {
                cursor: pointer;
                background-color: ${disabled ? theme.colors.medium : lighten(lightenAmount.hover, color)};
            }
        `;
    }}

    ${({ theme, white, noFocus }) =>
        white &&
        css`
            color: ${theme.colors.black};
            background-color: white;

            &:hover {
                background-color: ${theme.greys[50]};
            }

            &:focus {
                outline: none;
                box-shadow: ${noFocus ? 'none' : `0 0 0 0.2rem $${theme.greys[100]}`};
            }
        `}

    ${({ size, loading, startIcon, endIcon }) =>
        size === 'lg' &&
        css`
            height: 3.5rem;
            padding: 0 40px 0 ${!(startIcon || endIcon) && loading ? 12 : 40}px;
        `}

    ${({ size, rounded }) =>
        size === 'lg' &&
        rounded &&
        css`
            border-radius: 1.75rem;
        `}

    ${({ isOutline, theme, brand }) => {
        if (isOutline) {
            const color = theme.brand[brand || 'blue'];

            return css`
                border-style: solid;
                border-width: 1px;
                border-color: ${color};
                background-color: white;
                color: ${theme.colors.black};

                &:hover {
                    background-color: white;
                }
            `;
        }
    }}

    ${({ isFullWidth }) =>
        isFullWidth &&
        css`
            width: 100%;
        `} 
`;

export const Loading = styled.div<{
    color?: string;
    icon?: boolean;
}>`
    width: 1rem;
    height: 1rem;
    margin-right: ${({ icon }) => (icon ? 'calc(16px + 0.25rem)' : '12px')};
    margin-left: ${({ icon }) => (icon ? 0.25 : 0)}rem;
    border: 2px solid ${({ color }) => color};
    animation: rotate 1s linear infinite;
    border-left-color: transparent;
    border-radius: 50%;
`;

export const StartIcon = styled(Icon) <{ noMargin?: boolean }>`
    margin-right: ${({ noMargin }) => (noMargin ? 0 : '16px')};
`;

export const EndIcon = styled(Icon) <{ noMargin?: boolean }>`
    margin-left: ${({ noMargin }) => (noMargin ? 0 : '16px')};
`;

