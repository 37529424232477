import { useApi } from 'hooks/api';
import { ActiveEnvironmentEnum, useSession } from 'contexts/sessionContext';
import { SessionModel } from 'api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export enum GenderEnum {
    Male = 0,
    Female = 1
}

export enum EmailInfoResponseTypeEnum {
    NotExisting = 1,
    NotCompleted = 2,
    Completed = 3
}

export interface EditProfileDetailsModel {
    id: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    email: string;
}

export interface UserProfileModel {
    id: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    email: string;
}

export interface UserOverviewModel {
    id: string;
    fullName: string;
    email: string;
    created: string;
    changed: string;
}

export interface PasswordForgottenModel {
    userName: string;
}

export interface ResetPasswordModel {
    code: string;
    userName: string;
    password: string;
    confirmPassword: string;
}

export interface DeleteAccountInputModel {
    token: string;
    userId: string;
}

export const useUser = () => {
    const { callApi, refreshSession } = useApi();
    const { t } = useTranslation('gyms');
    const { clearSession } = useSession();

    const switchActiveGym = async (gymId: string) => {
        const response = await callApi.current<SessionModel>(
            '/Account/SwitchActiveGym',
            {
                method: 'PUT',
                body: JSON.stringify({ gymId })
            }
        );

        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('gyms:switch.error'));
        }

        return response;
    };

    const switchActiveEmployer = async (employerId: string) => {
        const response = await callApi.current<SessionModel>(
            '/Account/SwitchActiveEmployer',
            {
                method: 'PUT',
                body: JSON.stringify({ employerId })
            }
        );

        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('employers:switch.error'));
        }

        return response;
    };

    const switchActiveLocation = async (locationId: string) => {
        const response = await callApi.current<SessionModel>(
            '/Account/SwitchActiveLocation',
            {
                method: 'PUT',
                body: JSON.stringify({ locationId })
            }
        );

        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('locations:switch.error'));
        }

        return response;
    };

    const switchActiveAssociation = async (associationId: string) => {
        const response = await callApi.current<SessionModel>(
            '/Account/SwitchActiveAssociation',
            {
                method: 'PUT',
                body: JSON.stringify({ associationId })
            }
        );
        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('associations:switch.error'));
        }
        return response;
    };

    const switchActiveContentEnvironment = async (contentEnvironmentId: string) => {
        const response = await callApi.current<SessionModel>(
            '/Account/SwitchActiveContentEnvironment',
            {
                method: 'PUT',
                body: JSON.stringify({ contentEnvironmentId })
            }
        );
        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('associations:switch.error'));
        }
        return response;
    };

    const switchActiveSupplierEnvironment = async (supplierEnvironmentId: string) => {
        const response = await callApi.current<SessionModel>(
            '/Account/SwitchActiveSupplierEnvironment',
            {
                method: 'PUT',
                body: JSON.stringify({ supplierEnvironmentId })
            }
        );
        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('associations:switch.error'));
        }
        return response;
    };

    const switchActiveEnvironment = async (environment: ActiveEnvironmentEnum) => {
        const response = await callApi.current<SessionModel>(
            '/Account/SwitchActiveEnvironment',
            {
                method: 'PUT',
                body: JSON.stringify({ Environment: environment })
            }
        );

        if (response.ok) {
            await refreshSession();
        } else {
            if (environment === ActiveEnvironmentEnum.Location) {
                toast.error(t('common:switchEnvironment.locationError'));
            } else {
                toast.error(t('common:switchEnvironment.error'));
            }
        }

        return response;
    };

    const currentProfileInfo = async () => {
        const response = callApi.current<UserProfileModel>('/User/CurrentProfileInfo');

        return response;
    };

    const editProfileDetails = async (values: EditProfileDetailsModel) => {
        const response = await callApi.current<UserProfileModel>(
            '/User',
            {
                method: 'PUT',
                body: JSON.stringify(values)
            }
        );

        return response;
    };

    const allUserOverviews = async () => {
        const response = await callApi.current<UserOverviewModel[]>(
            '/user');
        return response;
    };

    const passwordForgotten = async (values: PasswordForgottenModel) => {
        const response = await callApi.current(
            '/Account/forgotPassword',
            {
                method: 'POST',
                body: JSON.stringify(values)
            }
        );

        return response;
    };

    const resetPassword = async (values: ResetPasswordModel) => {
        const response = await callApi.current(
            '/Account/resetPassword',
            {
                method: 'PUT',
                body: JSON.stringify(values)
            }
        );

        return response;
    };

    const checkUsername = async (username: string) => {
        const response = await callApi.current<EmailInfoResponseTypeEnum>(`/Account/${username}/checkUsername`);

        return response;
    };

    const logOut = async () => {
        const response = await callApi.current(
            '/account/cookielogout',
            {
                method: 'POST'
            }
        );

        if (response.ok) {
            clearSession();
        }

        return response;
    };

    const deleteUser = async (model: DeleteAccountInputModel) => {
        const response = await callApi.current('/account/deleteAccount',
            { method: 'DELETE', body: JSON.stringify(model) });
        return response;
    };

    const requestDeleteUser = async (userId: string) => {
        const response = await callApi.current('/account/requestAccountDeletion',
            { method: 'POST', body: JSON.stringify({ userId }) });
        return response;
    };

    return {
        switchActiveGym,
        switchActiveEmployer,
        switchActiveAssociation,
        switchActiveEnvironment,
        switchActiveContentEnvironment,
        switchActiveSupplierEnvironment,
        currentProfileInfo,
        editProfileDetails,
        allUserOverviews,
        passwordForgotten,
        resetPassword,
        switchActiveLocation,
        checkUsername,
        logOut,
        deleteUser,
        requestDeleteUser
    };
};
