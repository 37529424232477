import numeral from 'numeral';
import 'numeral/locales/nl-nl';

numeral.locale('nl-nl');

export function formatCurrency(
    value: number,
    format = '$0,0.00'
) {
    return formatNumber(value, format);
}

export function formatNumber(
    value: number,
    format = '0.00'
) {
    return numeral(value).format(format);
}

export function formatCurrencyInput(
    value: string,
    format = '$0.00'
) {
    return numeral(value).format(format);
}
