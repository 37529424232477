import React, { FC, Dispatch, SetStateAction } from 'react';
import { Tabs, Tab } from './styles';
import { H3 } from 'components/atoms/text';

interface Tab {
    title: string;
    notifications?: number;
}

export interface CardTabsProps {
    tabs: Tab[];
    activeIndex: number;
    setActiveIndex: Dispatch<SetStateAction<number>>;
}

const CardTabs: FC<CardTabsProps> = ({ tabs, activeIndex, setActiveIndex }) => {
    // Methods.
    const handleOnTabClick = (index: number) => () => {
        setActiveIndex(index);
    };

    // Render.
    return (
        <Tabs>
            {tabs.map((tab, index) => {
                const active = index === activeIndex;

                return (
                    <Tab key={index} active={active} onClick={handleOnTabClick(index)}>
                        <H3 variant={active ? 'black' : 'disabled'} notifications={tab.notifications}>
                            {tab.title}
                        </H3>
                    </Tab>
                );
            })}
        </Tabs>
    );
};

export default CardTabs;
