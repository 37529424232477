import Icon from 'components/atoms/Icon';
import React, { useCallback, useRef, useState } from 'react';
import { IconWrapper, Wrapper, InputWrapper, Input, Item, Items, Text, Title, Subtitle } from './styles';
import { debounce } from 'lodash-es';

export interface FormCustomerSearchProps<T> {
    value?: string; // Id of selected value.
    items: T[];
    getItem: (item: T) => Item;
    onSearch: (search: string) => void;
    onItemClick?: (id: string) => void;
    isDebounced?: boolean;
    placeholder?: string;
}

export interface Item {
    id: string;
    title: string;
    subtitle: string;
}

const FormItemSearch = <T extends { id: string }>({
    items: typedItems,
    value,
    onSearch,
    getItem,
    onItemClick,
    isDebounced,
    placeholder
}: FormCustomerSearchProps<T>) => {
    const ref = useRef<HTMLInputElement>(null);
    const [search, setSearch] = useState<string>('');

    // Render.
    // const idle = !(search.length > 0) && !(typedItems.length > 0);
    const items: Item[] = typedItems.map(getItem);

    // Methods.
    const handleOnItemClick = (id: string) => {
        if (onItemClick) {
            onItemClick(id);
        }
    };

    const handleOnChangeDebounced = useCallback(debounce(onSearch, 300), []);

    return (
        <Wrapper>
            <InputWrapper isEmpty={items.length === 0}>
                <IconWrapper onClick={() => ref.current?.focus()}>
                    {/* TODO color in var. */}
                    <Icon color="#ADADAD" name="search" size={1} />
                </IconWrapper>
                <Input
                    placeholder={placeholder}
                    ref={ref}
                    value={search}
                    onChange={(event) => {
                        const value = event.target.value;
                        setSearch(value);

                        if (isDebounced) {
                            handleOnChangeDebounced(value);
                        } else {
                            onSearch(value);
                        }
                    }}
                />
            </InputWrapper>
            {items.length > 0 && (
                <Items>
                    {items.map((item, index) => {
                        const isActive = item.id === value;

                        return (
                            <Item key={index} isActive={isActive} onClick={() => handleOnItemClick(item.id)}>
                                <Text>
                                    <Title>{item.title}</Title>
                                    <Subtitle>{item.subtitle}</Subtitle>
                                </Text>
                            </Item>
                        );
                    })}
                </Items>
            )}
        </Wrapper>
    );
};

export default FormItemSearch;

