import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Loading = styled.div<{ isFull?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${({ isFull }) => isFull && css`
        width: 100%;
        height: 100%;
    `}
`;

export const Text = styled.span`
    color: #9ca2b7; // color is only used here (for now),
    font-weight: 600;
    font-size: 1rem;
    line-height: 1;
`;

export const Ball = styled.div<{ isLast?: boolean }>`
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: ${({ isLast }) => (isLast ? 0 : '0.25rem')};
    background-color: ${({ theme }) => theme.colors.blue};
    animation: jump 0.5s ease-in-out infinite alternate;

    &:nth-child(2) {
        animation-delay: 0.2s;
        background-color: ${({ theme }) => theme.colors.yellow};
    }

    &:nth-child(3) {
        animation-delay: 0.4s;
        background-color: ${({ theme }) => theme.colors.pink};
    }

    @keyframes jump {
        0% {
            transform: 0;
        }
        100% {
            transform: translateY(0.5rem);
        }
    }
`;

export const Balls = styled.div`
    display: flex;
    height: 1.5rem;
`;
