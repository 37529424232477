import React, { FC } from 'react';
import { Modal as Wrapper, Overlay, Content, Header } from './styles';
import { Transition } from 'react-transition-group';
import { CardHeader } from 'components/atoms/card';

const DURATION = 300;

export interface ModalProps {
    inProp: boolean;
    onExited: () => void;
    onHide: () => void;
    size?: 'small' | 'medium' | 'large';
    title?: string;
    titleRight?: string;
}

const Modal: FC<ModalProps> = ({ children, inProp, onExited, onHide, size = 'medium', title, titleRight }) => {
    return (
        <Transition in={inProp} onExited={onExited} timeout={DURATION}>
            {(state) => (
                <Overlay state={state} duration={DURATION} onClick={onHide}>
                    <Wrapper state={state} duration={DURATION} onClick={(e) => e.stopPropagation()} size={size}>
                        {title && (
                            <Header>
                                <CardHeader title={title} cta={titleRight} />
                            </Header>
                        )}
                        <Content>{children}</Content>
                    </Wrapper>
                </Overlay>
            )}
        </Transition>
    );
};

export default Modal;
