import React, { FC, useMemo } from 'react';
import { H4 as Text, Wrapper, H4Icon as Icon } from './styles';
import { useTheme } from '@emotion/react';
import { Theme } from 'static/theme';
import { Colors } from 'static/theme/colors';

export interface H4Props {
    variant?: keyof Colors;
    grey?: number;
    center?: boolean;
    className?: string;
    icon?: string;
    onClick?: () => void;
}

const H4: FC<H4Props> = ({ children, variant, grey, center, className, icon, onClick }) => {
    const theme = useTheme() as Theme;
    const color = useMemo(() => (variant ? theme.colors[variant] : grey ? theme.greys[grey] : theme.colors.black), [variant, grey]);

    return (
        <Wrapper onClick={onClick}>
            {icon && <Icon name={icon} color={color} size={1} />}
            <Text color={color} center={center} className={className} underline={!!onClick}>
                {children}
            </Text>
        </Wrapper>
    );
};

export default H4;
