import React, { FC, InputHTMLAttributes } from 'react';
import Icon from 'components/atoms/Icon';
import { Checkbox, HiddenCheckbox, Label, Wrapper } from './styles';
import colors from 'static/theme/colors';
import InvalidFeedback from '../InvalidFeedback';

export interface FormCheckProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: string;
}

const FormCheck: FC<FormCheckProps> = ({ label, checked, error, ...props }) => {
    return (
        <>
            <Wrapper label={label}>
                <HiddenCheckbox type="checkbox" checked={checked} {...props} />
                <Checkbox checked={checked}>
                    {checked && <Icon name="check-bold" size={.75} color={colors.white} />}
                </Checkbox>
                {label && <Label>{label}</Label>}
            </Wrapper>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormCheck;
