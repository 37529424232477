import Alert from 'components/molecules/Alert';
import Panel from 'components/organisms/Panel';
import React, { useState } from 'react';
import useModal from '../modal';

export interface ConfirmPanelProps {
    title: string;
    subtitle?: string;
    description: string;
    onConfirm: (id?: string) => void;
}

const useConfirmPanel = (props: ConfirmPanelProps): [(id?: string) => void, () => void, boolean] => {
    const [id, setId] = useState<string>();

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Panel
                onHide={hide}
                inProp={inProp}
                onExited={onExited}
                title={props.title}
                subtitle={props.subtitle}
                buttons={[
                    { title: 'Annuleren', onClick: hide, isOutline: true },
                    { title: 'Bevestigen', onClick: handleOnSubmit }
                ]}
                onCloseSide={false}
            >
                <Alert title={props.title} type="warning" description={props.description} />
            </Panel>
        ),
        [id]
    );

    // Methods.
    const handleOnSubmit = () => {
        hide();
        props.onConfirm(id);
    };

    const handleShow = (id?: string) => {
        setId(id);
        show();
    };

    return [handleShow, hide, isShowing];
};

export default useConfirmPanel;

