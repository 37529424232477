import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';
import React, { FC } from 'react';
import { Pagination as Wrapper, NavButton, Number } from './styles';

export interface PaginationProps {
    pageIndex: number;
    pageSize: number;
    pageCount: number;
    gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
    nextPage: () => void;
    previousPage: () => void;
    canPreviousPage?: boolean;
    canNextPage?: boolean;
}

const pagination = (current: number, length: number, isMobile?: boolean) => {
    const delta = isMobile ? 1 : 2;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];

    for (let i = 1; i <= length; i++) {
        if (i === 1 || i === length || (i >= left && i < right)) {
            range.push(i);
        }
    }

    let l: number | undefined = undefined;
    for (const i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push(undefined);
            }
        }

        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
};

const Pagination: FC<PaginationProps> = ({ pageIndex, pageCount, gotoPage, previousPage, nextPage, canPreviousPage, canNextPage }) => {
    const pages = pagination(pageIndex, pageCount);
    const theme = useTheme();

    return (
        <Wrapper>
            {canPreviousPage &&
                <li>
                    <NavButton onClick={previousPage}>
                        <Icon name="chevron-left" color={theme.colors.dark} size={1.5} />
                    </NavButton>
                </li>
            }
            {pages.map((number, index) => {
                if (number) {
                    const page = number - 1;

                    // Don't use <li but a styled component '<Page active={pageIndex === page} />' (or something)
                    return (
                        <li key={index}>
                            <Number onClick={() => gotoPage(page)} active={pageIndex === page}>{number}</Number>
                        </li>
                    );
                }

                return <li key={index}>...</li>;
            })}
            {canNextPage &&
                <li>
                    <NavButton onClick={nextPage}>
                        <Icon name="chevron-right" color={theme.colors.dark} size={1.5} />
                    </NavButton>
                </li>
            }
        </Wrapper>
    );
};

export default Pagination;
