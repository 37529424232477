import { useField } from 'formik';
import React, { FC } from 'react';
import FormDropFile, { FormDropFileProps } from '..';

export interface FormikFormFileProps extends FormDropFileProps {
    name: string;
    image?: string;
}

const FormikFormFile: FC<FormikFormFileProps> = ({ name, image, ...props }) => {
    const [field, meta, helpers] = useField<File>(name);
    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;
    
    return <FormDropFile {...props} value={field.value} onChange={helpers.setValue} error={error} image={image} />;
};

export default FormikFormFile;
