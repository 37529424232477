import React from 'react';
import { CellProps, Hooks } from 'react-table';
import ActionComponent, { Action } from 'components/atoms/Action';

// eslint-disable-next-line @typescript-eslint/ban-types
const useActions = <T extends object>(actions?: Action<T>[]) => {
    return (hooks: Hooks<T>) => {
        if (!actions?.length) {
            return;
        }

        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                id: 'action',
                Header: 'Acties',
                width: actions.length * 30,
                Cell: ({ row }: CellProps<T>) => {
                    return (
                        <div style={{ display: 'flex' }}>
                            {actions.map((action, index) => (
                                <ActionComponent<T> {...action} item={row.original} index={row.index} key={index} />
                            ))}
                        </div>
                    );
                }
            }
        ]);
    };
};

export default useActions;
