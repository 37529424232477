import { useField } from 'formik';
import React from 'react';
import FormSelect, { FormSelectProps } from '..';

interface FormikFormSelectlProps<T extends number | string> extends FormSelectProps<T> {
    name: string;
    afterChange?: (value: T) => void;
}

const FormikFormSelect = <T extends number | string>({ name, options, afterChange, ...props }: FormikFormSelectlProps<T>) => {
    const [field, meta, helpers] = useField<T>(name);

    // FormikFormSelect's methods.
    const handleOnChange = (value: T) => {
        helpers.setValue(value);
        helpers.setTouched(true);
        afterChange && afterChange(value);
    };

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormSelect {...props} value={field.value} onChange={handleOnChange} error={error} options={options} />;
};

export default FormikFormSelect;
