import React, { useState } from 'react';
import useModal from '../modal';
import Panel from 'components/organisms/Panel';
import { Formik, FormikHelpers } from 'formik';
import Alert from 'components/molecules/Alert';
import { EditFitnessProductModel, useFitnessProduct } from 'hooks/fitnessProducts';
import { useTranslation } from 'react-i18next';
import { toFormikErrors } from 'utils/errorhelper';
import { useSession } from 'contexts/sessionContext';
import AddSupplierProductForm from '../addSupplierProduct/components/AddSupplierProductForm';
import * as yup from 'yup';

const useEditSupplierProduct = (onRefresh: () => void): [(initialValues: EditFitnessProductModel) => void, () => void, boolean] => {
    const [initialValues, setInitialValues] = useState<EditFitnessProductModel>();
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const { t } = useTranslation('templateFitnessProducts');
    const { editFitnessProduct } = useFitnessProduct();
    const { session } = useSession();

    const schemaValidatorObject = yup.object().shape({
        name: yup.string()
            .required(t('validation.required', { value: t('form.label.name') }))
            .max(128, t('validation.maxLength', { value: t('form.label.name'), max: '128' })),
        description: yup.string()
            .required(t('validation.required', { value: t('form.label.description') }))
            .max(256, t('validation.maxLength', { value: t('form.label.description'), max: '256' })),
        images: yup.array()
            .of(yup.object())
            .min(1, t('validation.images.required')),
        purchasePrice: yup.number()
            .required(t('validation.required', { value: t('form.label.price') })),
        numberType: yup.string()
            .required(t('validation.required', { value: t('form.label.typeNumber') }))
    });

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) =>
            initialValues ? (
                !isEdited ? (
                    <Formik<EditFitnessProductModel>
                        initialValues={initialValues}
                        onSubmit={handleOnSubmit}
                        validationSchema={schemaValidatorObject}
                    >
                        {({ handleSubmit, touched }) => (
                            <Panel
                                onHide={handleHide}
                                inProp={inProp}
                                onExited={onExited}
                                title={t('title')}
                                subtitle={t('form.edit.title')}
                                buttons={[
                                    { title: t('common:button.cancel'), onClick: handleHide, isOutline: true },
                                    { title: t('common:button.save'), onClick: () => handleSubmit() }
                                ]}
                                onTouched={touched}
                            >
                                <AddSupplierProductForm mode={'edit'} />
                            </Panel>
                        )}
                    </Formik>
                ) : (
                    <Panel
                        onHide={handleHide}
                        inProp={inProp}
                        onExited={onExited}
                        title={t('title')}
                        subtitle={t('form.add.title')}
                        buttons={[{ title: t('common:button.confirm'), onClick: handleHide }]}
                    >
                        <Alert
                            title={t('confirm.title')}
                            description={t('confirm.description.edit')}
                        />
                    </Panel>
                )
            ) : null,
        [initialValues, isEdited]
    );

    // Methods.
    const handleHide = () => {
        hide();
        setIsEdited(false);
    };

    const handleShow = (initialValues: EditFitnessProductModel) => {
        setInitialValues(initialValues);
        show();
    };

    const handleOnSubmit = async (values: EditFitnessProductModel, helpers: FormikHelpers<EditFitnessProductModel>) => {
        helpers.setSubmitting(true);
        if (session?.activeSupplierId === undefined) return;
        values.supplierId = session?.activeSupplierId;

        const response = await editFitnessProduct(values);

        if (response.ok) {
            setIsEdited(true);
            onRefresh();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        }
        helpers.setSubmitting(false);
    };

    return [handleShow, handleHide, isShowing];
};

export default useEditSupplierProduct;

