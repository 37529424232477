import { DropdownItemProps } from 'components/atoms/item/DropdownItem';
import React, { FC, useCallback, useState } from 'react';
import { Dropdown as Wrapper, Header, Toggle } from './styles';
import { useLayer, Arrow } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import { DropdownMenu } from 'components/molecules/dropdown';
import { H4 } from 'components/atoms/text';
import { useTheme } from '@emotion/react';

export interface DropdownProps {
    items: ((close?: () => void) => DropdownItemProps[]) | DropdownItemProps[];
    onSubItemClick?: (value: string | number | boolean) => void;
    isFull?: boolean;
}

const Dropdown: FC<DropdownProps> = ({ children, items, onSubItemClick, isFull }) => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();

    // Dropdown's methods.
    const toggle = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        setIsOpen((open) => !open);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    // Laag.
    const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
        isOpen,
        onOutsideClick: close,
        onDisappear: close,
        overflowContainer: false,
        placement: 'bottom-start', // we prefer to place the menu "bottom-start"
        // possiblePlacements: ['bottom-start', 'bottom-end'],
        auto: true,
        triggerOffset: 8,
        containerOffset: 16,
        arrowOffset: 16
    });

    // Render.
    return (
        <Wrapper isFull={isFull} {...triggerProps}>
            <Header isFull={isFull} onClick={toggle}>
                {typeof children === 'string' ? <H4>{children}</H4> : children}
                <Toggle isFull={isFull} size={0.5} name="chevron-down-small" color={theme.colors.dark} />
            </Header>
            {renderLayer(
                <AnimatePresence>
                    {isOpen && (
                        <DropdownMenu close={close} items={items} {...layerProps} ref={layerProps.ref} onSubItemClick={onSubItemClick}>
                            <Arrow {...arrowProps} />
                        </DropdownMenu>
                    )}
                </AnimatePresence>
            )}
        </Wrapper>
    );
};

export default Dropdown;

