import { useApi } from 'hooks/api';
import { appendObject } from 'utils/objectAppender';
import { TimeFilter } from 'utils/timeFilterHelper/timeFilterHelper';
import { buildUriWithParams } from 'utils/uriHelper';

export type AllChallengeTemplateQueryOptions = {
    locationId?: string;
} | {
    employerId?: string;
} | {
    entreo?: boolean;
} | {
    associationId?: string;
} & {
    isActive?: boolean;
} & {

    }

export interface ActiveChallenge {
    goalType: GoalType;
    activityType?: ActivityType
}

export interface GoalInputModel {
    id?: string;
    measurableType: MeasurableType;
    activityCountType: ActivityCountType;
    externalActivityType?: ActivityType;
    requiredAmountToDo?: number;
    goalExpReward?: number;
}

export interface GoalModel {
    id: string;
    measurableType: MeasurableType;
    activityCountType: ActivityCountType;
    goalType: GoalType;
    requiredAmountToDo?: number;
    goalExpReward?: number;
    externalActivityType?: ActivityType;
}

export enum GoalType {
    TotalStepsInPeriod = 1,
    StepsPerDayInPeriod = 2,
    TotalGymVisitsInPeriod = 3,
    GymVisitsPerDayInPeriod = 4,
    ExternalDistanceInPeriod = 5,
    TotalExternalDistanceInPeriod = 6,
    StepsPerWeekInPeriod = 7,
    GymVisitsPerWeekInPeriod = 8,
    ExternalDistancePerWeekInPeriod = 9
}

export enum ChallengeThirdPartyType {
    Strava = 0,
    StepCounter = 1,
    HeartRhythmCounter = 2
}

export enum ChallengeRatingType {
    Beginner = 0,
    Advanced = 1,
    Expert = 2
}

export enum ChallengeGroupType {
    Single = 0,
    Group = 1
}

export enum ChallengeMeasurementUnitType {
    Steps = 0,
    HeartRhythm = 1,
    Minutes = 2,
    Hour = 3,
    Day = 4,
    Week = 5,
    Mililiter = 6,
    Centiliter = 7,
    Deciliter = 8,
    Liter = 9,
    Meter = 10,
    Kilometer = 11,
    Gram = 12,
    Pound = 13,
    Kilogram = 14,
    Fruit = 15,
    GymVisits = 16,
    Workout = 17
}

export enum ChallengeMeasurementType {
    Amount = 0,
    Distance = 1,
    Piece = 2,
    Weight = 3,
    Volume = 4,
    Duration = 5
}

export enum ChallengeThemeType {
    Movement = 0,
    Nutrition = 1,
    Relaxation = 2
}

export enum PictureBankThemeType {
    Movement = 0,
    Nutrition = 1,
    Relaxation = 2,
    All = 3
}

export enum ChallengeCompletionPeriodType {
    PerDay = 0,
    PerWeek = 1,
    PerMonth = 2
}

export enum ChallengeAvailabilityType {
    Internal = 1,
    Public = 2
}

export enum MeasurableType {
    Steps = 1,
    GymVisits = 2,
    ExternalDistance = 3
}

export enum ActivityAllType {
    Unknown = 0,
    Run = 1,
    Walk = 2,
    Swim = 3,
    Ride = 4,
    GymVisits = 5,
    FoodAndDrinks = 6,
    Meditation = 7
}

export enum ActivityCountType {
    PerDay = 1,
    PerWeek = 3,
    Total = 2,
}

export enum XpType {
    OnlyChallenge = 1,
    OnlyGoal = 2,
    ChallengeAndGoal = 3
}

export enum ActivityType {
    Unknown = 0,
    Run = 1,
    Walk = 2,
    Swim = 3,
    Ride = 4,
    GymVisits = 5,
    FoodAndDrinks = 6,
    Meditation = 7
}

export interface ChallengeTemplateFaceModel {
    id: string;
    name: string;
    avatar: string;
}

export enum ChallengeTemplateStatusType {
    Disabled = 0,
    Draft = 1,
    Published = 2
}

export interface ChallengeTemplateQueryOptions {
    isActive?: boolean;
    status?: ChallengeTemplateStatusType;
}

export interface ChallengeTemplatePlainModel {
    id: string;
    name: string;
    isActive: boolean;
    banner?: string;
    startDate?: Date;
    endDate?: Date;
    fromDate?: Date;
    fromEndDate?: Date;
    description: string;
    uniqueFeatureDescription?: string;
    benefitDescription?: string;
    activityDescription: string;
    daysRemaining: number;
    challengeLastsForDays: number;
    theme: ChallengeThemeType;
    completionAmount: number;
    completionPeriod: ChallengeCompletionPeriodType;
    frequencyAmount: number;
    frequencyPeriod: ChallengeCompletionPeriodType;
    measurementAmount: number;
    measurement: ChallengeMeasurementType;
    measurementUnit: ChallengeMeasurementUnitType;
    activity: ActivityType;
    difficulty: ChallengeRatingType;
    entreoPointsReward: number;
    status: ChallengeTemplateStatusType;
    publishDate?: Date;
    thirdParty?: ChallengeThirdPartyType;
    sendNotifications?: boolean;
}

export interface AddChallengeTemplateInputModel {
    employerId?: string;
    locationId?: string;
    associationId?: string;
    name: string;
    challengeBanner?: File;
    entreoPointsReward: number;
    description: string;
    benefitDescription?: string;
    uniqueFeatureDescription?: string;
    activityDescription: string;
    theme: ChallengeThemeType;
    completionAmount: number;
    completionPeriod: ChallengeCompletionPeriodType;
    frequencyAmount?: number;
    frequencyPeriod?: ChallengeCompletionPeriodType;
    frequencyPeriodAmount: number;
    measurementAmount: number;
    measurement: ChallengeMeasurementType;
    measurementUnit: ChallengeMeasurementUnitType;
    activity: ActivityType;
    difficulty: ChallengeRatingType;
    startDate?: Date;
    endDate?: Date;
    fromDate?: Date;
    fromEndDate?: Date;
    medalId?: string;
    pictureBankItemId?: string;
    status: ChallengeTemplateStatusType;
    thirdParty?: ChallengeThirdPartyType;
    sendNotifications: boolean;
}

export interface ChallengeTemplateModel {
    id: string;
    name: string;
    entreoPointsReward: number;
    description: string;
    uniqueFeatureDescription?: string;
    benefitDescription?: string;
    activityDescription: string;
    challengeBanner?: File;
    challengeBannerHash?: string;
    startDate?: Date;
    endDate?: Date;
    fromDate?: Date;
    fromEndDate?: Date;
    employerId?: string;
    locationId?: string;
    theme: ChallengeThemeType;
    completionPeriod: ChallengeCompletionPeriodType;
    frequencyAmount: number;
    frequencyPeriod: ChallengeCompletionPeriodType;
    frequencyPeriodAmount: number;
    completionAmount: number;
    measurementAmount: number;
    measurement: ChallengeMeasurementType;
    measurementUnit: ChallengeMeasurementUnitType;
    activity: ActivityType;
    isReceivingNotifications: boolean;
    difficulty: ChallengeRatingType;
    pictureBankItemId?: string;
    status: ChallengeTemplateStatusType;
    publishDate?: Date;
    thirdParty?: ChallengeThirdPartyType;
    sendNotifications: boolean;
}

export interface EditChallengeTemplateInputModel {
    id: string;
    name: string;
    entreoPointsReward: number;
    challengeBanner?: File;
    challengeBannerHash?: string;
    description: string;
    uniqueFeatureDescription?: string;
    benefitDescription?: string;
    activityDescription: string;
    theme: ChallengeThemeType;
    completionPeriod: ChallengeCompletionPeriodType;
    completionAmount: number;
    frequencyPeriod: ChallengeCompletionPeriodType;
    frequencyAmount: number;
    frequencyPeriodAmount: number;
    measurementAmount: number;
    measurement: ChallengeMeasurementType;
    measurementUnit: ChallengeMeasurementUnitType;
    activity: ActivityType;
    difficulty: ChallengeRatingType;
    startDate?: Date;
    endDate?: Date;
    fromDate?: Date;
    fromEndDate?: Date;
    medalId?: string;
    pictureBankItemId?: string;
    status: ChallengeTemplateStatusType;
    thirdParty?: ChallengeThirdPartyType;
    sendNotifications: boolean;
}

export interface PopularChallengesModel {
    measurableType: MeasurableType,
    percentageOfPeopleJoined: number,
    amountOfChallenges: number,
    amountDone: number
}

export interface PopularChallengesInputModel {
    timeFilter: TimeFilter,
    employerId?: string
}

export const useChallengeTemplate = () => {
    const { callApi } = useApi();

    const allChallengeTemplates = async (queryParams?: AllChallengeTemplateQueryOptions) => {
        const uri = buildUriWithParams('/challengeTemplate', queryParams);

        const response = await callApi.current<ChallengeTemplatePlainModel[]>(uri);

        return response;
    };

    const addChallengeTemplate = async (values: AddChallengeTemplateInputModel) => {
        const formData = new FormData();
        appendObject(values, formData);
        if (values.startDate !== undefined) {
            formData.append('startDate', values.startDate.toUTCString());
        }
        if (values.endDate !== undefined) {
            formData.append('endDate', values.endDate.toUTCString());
        }
        if (values.fromDate !== undefined) {
            formData.append('fromDate', values.fromDate.toUTCString());
        }
        if (values.fromEndDate !== undefined) {
            formData.append('fromEndDate', values.fromEndDate.toUTCString());
        }
        const response = await callApi.current('/challengeTemplate', {
            method: 'POST',
            body: formData
        });

        return response;
    };

    const setIsChallengeTemplateActive = async (challengeId: string, isActive: boolean) => {
        const response = await callApi.current<ChallengeTemplatePlainModel>(`/challengeTemplate/${challengeId}/SetIsActive`, {
            method: 'PUT',
            body: JSON.stringify({ isActive })
        });

        return response;
    };

    const editChallengeTemplate = async (values: EditChallengeTemplateInputModel) => {
        const formData = new FormData();
        appendObject(values, formData);
        if (values.startDate !== undefined) {
            formData.append('startDate', values.startDate.toUTCString());
        }
        if (values.endDate !== undefined) {
            formData.append('endDate', values.endDate.toUTCString());
        }
        if (values.fromDate !== undefined) {
            formData.append('fromDate', values.fromDate.toUTCString());
        }
        if (values.fromEndDate !== undefined) {
            formData.append('fromEndDate', values.fromEndDate.toUTCString());
        }
        if (values.challengeBanner !== undefined) {
            formData.append('challengeBanner', values.challengeBanner);
        }
        const response = await callApi.current('/challengeTemplate', {
            method: 'PUT',
            body: formData
        });
        return response;
    };

    const getChallengeTemplate = async (id: string) => {
        const response = await callApi.current<ChallengeTemplateModel>(`/challengeTemplate/${id}`);
        return response;
    };

    const removeChallengeTemplate = async (id: string) => {
        const response = await callApi.current(`/challengeTemplate/${id}`, {
            method: 'DELETE'
        });
        return response;
    };

    return {
        allChallengeTemplates,
        addChallengeTemplate,
        editChallengeTemplate,
        setIsChallengeTemplateActive,
        getChallengeTemplate,
        removeChallengeTemplate
    };
};
