import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

export const Step = styled.div<{ active?: boolean; disabled?: boolean; hightlight?: boolean }>`
    display: flex;
    align-items: center;
    height: 3.5rem;
    justify-content: center;
    position: relative;
    flex: 1;
    padding: 0 1rem;

    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                cursor: pointer;
            }
        `}
    ${({ active, theme, hightlight }) =>
        (active || hightlight) &&
        css`
            background-color: ${rgba(theme.colors.primary, 0.1)};
        `}


    ${({ active, theme }) =>
        active &&
        css`
            border-top-right-radius: 1.75rem;
            border-bottom-right-radius: 1.75rem;
        `}
`;

export const Number = styled.div<{ active?: boolean; disabled?: boolean; hightlight?: boolean }>`
    width: 1.75rem;
    height: 1.75rem;
    flex-shrink: 0;
    background-color: ${({ theme, active, disabled, hightlight }) =>
        active || hightlight ? theme.colors.primary : disabled ? theme.colors.disabled : theme.colors.primary};
    transition: background 0.4s ease;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.875rem;
    font-weight: 600;
    margin-right: 0.5rem;
`;

export const Content = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

export const Title = styled.span<{ active?: boolean; hightlight?: boolean }>`
    font-size: 0.875rem;
    line-height: 1;
    font-weight: 600;
    color: ${({ theme, active, hightlight }) => (active || hightlight ? theme.colors.primary : theme.colors.disabled)};
`;

export const Subtitle = styled.span`
    color: ${({ theme }) => theme.colors.disabled};
    font-size: 0.875rem;
    margin-top: 0.25rem;
    line-height: 0.875rem;
`;

