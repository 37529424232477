import { useTheme } from '@emotion/react';
import React from 'react';
import Icon from '../Icon';
import { Action as Wrapper } from './styles';

export interface Action<T> {
    icon: string;
    onClick?: (item: T, index: number) => void;
}

interface ActionProps<T> extends Action<T> {
    item: T;
    index: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ActionComponent = <T extends object>({ onClick, icon, item, index }: ActionProps<T>) => {
    const theme = useTheme();

    const handleOnClick = () => {
        if (onClick) {
            onClick(item, index);
        }
    };

    return (
        <Wrapper onClick={handleOnClick} type="button">
            <Icon name={icon} size={1.125} color={theme.greys[500]} />
        </Wrapper>
    );
};

export default ActionComponent;
