import styled from '@emotion/styled';

export const Points = styled.div`
    display: flex;
    align-items: center;
`;

export const Text = styled.span`
    color: ${({ theme }) => theme.colors.text};
    font-size: 0.875rem;
    font-weight: 600;
    margin-right: 8px;
    margin-left: 4px;
`;
