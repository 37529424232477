import React, { FC, MouseEvent } from 'react';
import { Button as Wrapper, Loading, StartIcon, EndIcon } from './styles';
import { readableColor } from 'polished';
import brands, { Brand } from 'static/theme/brand';

export interface ButtonProps {
    onClick?: (event?: MouseEvent) => void;
    brand?: keyof Brand;
    className?: string;
    loading?: boolean;
    rounded?: boolean;
    size?: 'md' | 'lg';
    startIcon?: string;
    iconColor?: string;
    endIcon?: string;
    type?: 'button' | 'submit';
    tabIndex?: number;
    title?: string;
    noFocus?: boolean;
    // Variants.
    disabled?: boolean;
    white?: boolean;
    alignSelf?: 'flex-start' | 'center';
    isOutline?: boolean;
    isFullWidth?: boolean;
}

const Button: FC<ButtonProps> = ({
    loading,
    disabled,
    onClick,
    children,
    brand,
    startIcon,
    endIcon,
    type,
    iconColor,
    title,
    alignSelf = 'flex-start',
    ...props
}) => {
    // Button's methods.
    const handleOnClick = (event?: MouseEvent) => {
        if (onClick && (!loading || disabled)) {
            onClick(event);
        }
    };

    // Render.
    const icon = !!(startIcon || endIcon);
    const color = brands[brand || 'blue'];

    return (
        <Wrapper
            startIcon={startIcon}
            endIcon={endIcon}
            loading={loading}
            disabled={disabled || loading}
            brand={brand}
            alignSelf={alignSelf}
            onClick={handleOnClick}
            type={type}
            {...props}
        >
            {!endIcon && loading ? (
                <Loading icon={icon} color={readableColor(color)} />
            ) : (
                startIcon && <StartIcon name={startIcon} color={iconColor ?? readableColor(color)} size={1} noMargin={!children} />
            )}
            {children || title}
            {endIcon &&
                (loading ? (
                    <Loading icon={icon} color={readableColor(color)} />
                ) : (
                    <EndIcon name={endIcon} color={readableColor(color)} size={1} noMargin={!children} />
                ))}
        </Wrapper>
    );
};

export default Button;
