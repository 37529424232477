import Icon from 'components/atoms/Icon';
import React, { FC, InputHTMLAttributes } from 'react';
import theme from 'static/theme';
import InvalidFeedback from '../InvalidFeedback';
import { FormControl as Input, Wrapper } from './styles';

export interface FormControlProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    error?: string;
    onChange?: (value: string) => void;
    startIcon?: string;
    endIcon?: string;
}

const FormControl: FC<FormControlProps> = ({ error, onChange, startIcon, endIcon, ...props }) => {
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.value);
        }
    };

    return (
        <>
            <Wrapper>
                {startIcon &&
                    <Icon name={startIcon} size={1} color={theme.colors.placeholder} />
                }
                <Input {...props} onChange={handleOnChange} />
                {endIcon &&
                    <Icon name={endIcon} size={1} color={theme.colors.placeholder} />
                }
            </Wrapper>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormControl;
