import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import Step2 from './components/AddChallengeForm';
import { useTranslation } from 'react-i18next';
import { ChallengeModel, EditChallengeInputModel, useChallenges } from 'hooks/challenge';
import Loading from 'components/atoms/Loading';
import { toast } from 'react-toastify';
import { Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import { ChallengeTemplatePlainModel } from 'hooks/challengeTemplate';
import { addDays } from 'date-fns';
import Panel from 'components/organisms/Panel';
import Alert from 'components/molecules/Alert';
import * as yup from 'yup';
import { values } from 'lodash';

export interface ChallengeValues {
    challengeTemplateId: string;
    challengeTemplatePlainModel?: ChallengeTemplatePlainModel;
    locationId?: string;
    employerId?: string;
    associationId?: string;
    hasDate: boolean;
    startDate?: Date;
    endDate?: Date;
    hasFromDate: boolean;
    fromDate?: Date;
    fromEndDate?: Date;
    entreoPointsReward: number;
    sendNotifications: boolean;
    isActive: boolean;
    createdByEntreo: boolean;
}

export interface EnvironmentProps {
    employerId?: string,
    locationId?: string,
    associationId?: string,
    entreo?: boolean
}

export interface ChallengeModalProps {
    environmentProps: EnvironmentProps;
    onRefresh: () => void;
}

const useChallengeModal = (props: ChallengeModalProps): [(challengeId?: string) => void, () => void, boolean] => {
    const { t } = useTranslation('challengeModal');
    const { getChallenge, editChallenge } = useChallenges();

    const [challenge, setChallenge] = useState<ChallengeModel | undefined>();
    const [challengeTemplate, setChallengeTemplate] = useState<ChallengeTemplatePlainModel>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const loadChallengeData = async (challengeId: string) => {
        setIsLoading(true);
        const response = await getChallenge(challengeId ?? '');

        if (response && response.data) {
            const challengeData = response.data;
            setChallenge(challengeData);
            setChallengeTemplate(challengeData.challengeTemplatePlainModel);
        }
        setIsLoading(false);
    };

    // Methods.
    const handleOnSubmit = async (values: ChallengeValues, helpers: FormikHelpers<ChallengeValues>) => {
        handleEditChallenge(values, helpers);
    };

    const handleEditChallenge = async (values: ChallengeValues, helpers: FormikHelpers<ChallengeValues>) => {
        const challengeInputModel: EditChallengeInputModel = {
            id: challenge?.id ?? '',
            challengeTemplateId: values.challengeTemplatePlainModel?.id ?? values.challengeTemplateId,
            locationId: props.environmentProps.locationId,
            employerId: props.environmentProps.employerId,
            associationId: props.environmentProps.associationId,
            fromDate: values.hasFromDate ? values.fromDate : undefined,
            fromEndDate: values.hasFromDate ? values.fromEndDate : undefined,
            startDate: values.hasDate ? values.startDate : undefined,
            endDate: values.hasDate ? values.endDate : undefined,
            entreoPointsReward: values.entreoPointsReward,
            isActive: values.isActive,
            sendNotifications: values.sendNotifications
        };
        helpers.setSubmitting(true);
        const response = await editChallenge(challengeInputModel);

        if (response.ok) {
            setIsSuccess(true);
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        helpers.setSubmitting(false);
    };

    const setInitialValues = () => {
        const tomorrow = addDays(new Date(), 1);

        return {
            challengeTemplateId: challenge?.challengeTemplateId ?? '',
            locationId: challenge?.locationId,
            employerId: challenge?.employerId,
            associationId: challenge?.associationId,
            hasDate: challenge?.endDate !== null && challenge?.startDate !== null ? true : false,
            startDate: challenge?.startDate ? new Date(challenge.startDate) : new Date(),
            endDate: challenge?.endDate ? new Date(challenge.endDate) : tomorrow,
            hasFromDate: challenge?.fromDate !== null && challenge?.fromEndDate !== null ? true : false,
            fromDate: challenge?.fromDate ? new Date(challenge.fromDate) : new Date(),
            fromEndDate: challenge?.fromEndDate ? new Date(challenge.fromEndDate) : tomorrow,
            isActive: challenge?.isActive ?? false,
            entreoPointsReward: challenge?.entreoPointsReward ?? 1,
            sendNotifications: challenge?.sendNotifications ?? false,
            createdByEntreo: !!props.environmentProps.entreo
        };
    };

    const validationSchema = yup.object().shape({
        entreoPointsReward: yup
            .string()
            .required(t('step2.isRequired'))
            .matches(/^[0-9]+$/, {
                message: t('step2.number'),
                excludeEmptyString: true
            })
            .test('number', t('step2.number'), (value?: string) => !isNaN((Number(value))))
            .test('positive', t('step2.positive'), (value?: string) => Number(value) > 0),
        sendNotifictions: yup.bool(),
        startDate: yup.date().when('hasDate', {
            is: true,
            then: yup.date().max(yup.ref('endDate'), (t('step2.maxDate'))).required(t('step2.isRequired'))
        }),
        endDate: yup.date().when('hasDate', {
            is: true,
            then: yup.date().min(yup.ref('startDate'), (t('step2.minDate')))
        }),
        fromDate: yup.date().when('hasFromDate', {
            is: true,
            then: yup.date().max(yup.ref('fromEndDate'), (t('step2.maxDate'))).required(t('step2.isRequired'))
        }),
        fromEndDate: yup.date().when('hasFromDate', {
            is: true,
            then: yup.date().min(yup.ref('fromDate'), (t('step2.minDate')))
        }),
    });
    
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            if (isLoading) {
                return (
                    <Modal onHide={onHideModal} inProp={inProp} onExited={onExited} title={t('title')} size="large">
                        <Loading />
                    </Modal>
                );
            }
            const initialValues = setInitialValues();
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            return (
                !isSuccess ?
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleOnSubmit}
                        validationSchema={validationSchema}
                        validateOnBlur={false}
                        validateOnMount
                        validateOnChange
                    >
                        {({ handleSubmit, isSubmitting, touched, isValid }) => (
                            <Panel
                                onHide={hide}
                                inProp={inProp}
                                onExited={onExited}
                                title={t('title')}
                                subtitle={t('subtitle')}
                                buttons={[
                                    { title: t('common:button.cancel'), onClick: () => handleHide(), isOutline: true },
                                    { title: t('common:button.confirm'), onClick: () => handleSubmit(), type: 'submit', disabled: (isSubmitting || !isValid) }
                                ]}
                                onTouched={touched}
                            >
                                <Step2 template={challengeTemplate} />
                            </Panel>
                        )}
                    </Formik>
                    :
                    <Panel
                        onHide={hide}
                        inProp={inProp}
                        onExited={onExited}
                        title={t('title')}
                        subtitle={t('subtitle')}
                        buttons={[{ title: t('common:button.confirm'), onClick: () => handleHide() }]}
                    >
                        <Alert
                            title={t('title')}
                            description={t('common:message.save')}
                        />
                    </Panel>

            );
        },
        [isLoading, challenge, challengeTemplate, isSuccess, values]
    );

    const showModal = useCallback(async (challengeId?: string) => {
        if (challengeId) {
            loadChallengeData(challengeId);
        }
        show();
    }, [show]);

    const handleHide = async () => {
        setIsSuccess(false);
        setChallengeTemplate(undefined);
        setChallenge(undefined);
        hide();
        await props.onRefresh();
    };

    const onHideModal = () => {
        setChallenge(undefined);
        setChallengeTemplate(undefined);

        hide();
    };

    return [showModal, hide, isShowing];
};

export default useChallengeModal;
