import { rgba } from 'polished';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CardProps } from '../Card';

export const Card = styled.div<CardProps>`
    height: ${({ fill }) => (fill ? '100%' : 'auto')};
    padding-bottom: ${({ noMargin }) => (noMargin ? 0 : '2rem')};
`;

export const Inner = styled.div<CardProps>`
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 10px 30px ${({ theme }) => rgba(theme.colors.dark, 0.07)};
    border-radius: ${({ theme }) => theme.borderRadius.large}px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: ${({ overflow }) => overflow};
    height: ${({ fill }) => (fill ? '100%' : 'auto')};

    ${({ noShadow }) =>
    noShadow &&
    css`
        box-shadow: none;
    `}

    ${({ theme, variant }) => {
        const color = theme.colors[variant || 'white'];

        return css`
            background-color: ${color};
        `;
    }};
`;
