import styled from '@emotion/styled';

export const Header = styled.div`
    position: relative;
    padding: 1rem ${({ theme }) => theme.card.padding.horizontal}px;
    min-height: 4.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.light};
    font-weight: 500;
    font-size: 0.825rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .swiper-pagination {
        bottom: 0;
        position: relative;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Right = styled.div`
    display: flex;
    justify-content: flex-end;
`;
