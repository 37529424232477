import Icon from 'components/atoms/Icon';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Dropdown = styled.div<{ isFull?: boolean }>`
    display: flex;
    position: relative;
    z-index: 5;
    height: 100%;
    padding: 0 ${({ theme }) => theme.form.padding.horizontal}px;
    
    ${({ isFull }) =>
        isFull &&
        css`
            flex: 1;
        `}
`;

export const Header = styled.div<{ isFull?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;

    ${({ isFull }) =>
        isFull &&
        css`
            flex: 1;
        `}
`;

export const Toggle = styled(Icon)<{ isFull?: boolean }>`
    margin-left: 8px;

    &:hover {
        cursor: pointer;
    }

    ${({ isFull }) =>
        isFull &&
        css`
            margin-left: auto;
        `}
`;

