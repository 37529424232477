import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import Wizard from 'components/organisms/Wizard';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { AddStoreItemInputModel, EditStoreItemInputModel, StoreItemCategory, StoreItemPlainModel, useStore } from 'hooks/store';
import Loading from 'components/atoms/Loading';


export interface ProductValues {
    id?: string
    name: string,
    description: string,
    cost: number,
    image?: File,
    storeItemCategory: StoreItemCategory,
    employerId?: string,
    stock?: number,
    cdnHash?: string
}

interface EnvironmentProps {
    employerId?: string;
    locationId?: string;
    associationId?: string;
    entreo?: boolean;
}

const useProductModal = (environmentProps: EnvironmentProps): [(storeItemId?: string) => void, () => void, boolean] => {
    const theme = useTheme();
    const { t } = useTranslation('productModal');
    const { getStoreItem, addStoreItem, editStoreItem } = useStore();

    const [storeItem, setStoreItem] = useState<StoreItemPlainModel | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const loadStoreItemData = async (storeItemId: string) => {
        setIsLoading(true);
        const response = await getStoreItem(storeItemId);
        if (response.ok && response.data) {
            setStoreItem(response.data);
        }
        setIsLoading(false);
    };

    // Methods.
    const handleOnSubmit = async (values: ProductValues) => {
        if (!!storeItem) {
            handleEditStoreItem(values);
        } else {
            handleAddStoreItem(values);
        }
    };

    const handleAddStoreItem = async (values: ProductValues) => {
        const storeItemInputModel: AddStoreItemInputModel = {
            name: values.name,
            description: values.description,
            cost: values.cost,
            storeItemCategory: values.storeItemCategory,
            employerId: environmentProps.employerId,
            image: values.image,
            stock: values.stock
        };
        const response = await addStoreItem(storeItemInputModel);
        if (response.ok) {
            window.location.reload();
        } else {

        }
    };

    const handleEditStoreItem = async (values: ProductValues) => {
        const storeItemInputModel: EditStoreItemInputModel = {
            id: values.id ?? '',
            name: values.name,
            description: values.description,
            cost: values.cost,
            storeItemCategory: values.storeItemCategory,
            employerId: environmentProps.employerId,
            stock: values.stock,
            image: values.image,
            cdnHash: values.cdnHash ?? ''
        };
        const response = await editStoreItem(storeItemInputModel);
        if (response.ok) {
            window.location.reload();
        } else {

        }
    };

    const setInitialValues = () => {
        return {
            id: storeItem?.id ?? '',
            name: storeItem?.name ?? '',
            description: storeItem?.description ?? '',
            cost: storeItem?.cost ?? 0,
            storeItemCategory: storeItem?.storeItemCategory ?? StoreItemCategory.None,
            employerId: environmentProps.employerId,
            stock: storeItem?.stock ?? undefined,
            image: undefined,
            cdnHash: storeItem?.cdnHash ?? ''
        };
    };

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            if (isLoading) {
                return (<Modal onHide={onHideModal} inProp={inProp} onExited={onExited} title={t('title')} size="large"> <Loading /> </Modal>);
            }
            const initialValues = setInitialValues();

            return (
                <Modal onHide={onHideModal} inProp={inProp} onExited={onExited} title={t('title')} size="large" titleRight="#123">
                    <div style={{ marginTop: -theme.card.padding.horizontal }}>
                        <Wizard<ProductValues>
                            initialValues={initialValues}
                            initialStep={0}
                            onSubmit={handleOnSubmit}
                            onSubmitLabel={t('addButton')}
                            steps={[
                                {
                                    title: t('step1.title'),
                                    child: <Step1 />,
                                    validationSchema: yup.object().shape({
                                        name: yup.string().required(t('step1.required.name')),
                                        description: yup.string().required(t('step1.required.description'))
                                    }),
                                    step: 1 // Number that is being displayed.
                                },
                                {
                                    title: t('step2.title'),
                                    child: <Step2 />,
                                    step: 2 // Number that is being displayed.
                                }
                            ]}
                        />
                    </div>
                </Modal>
            );
        },
        [isLoading, storeItem]
    );

    const showModal = useCallback(async (storeItemId?: string) => {
        if (storeItemId) {
            loadStoreItemData(storeItemId);
        }
        show();
    }, [show]);

    const onHideModal = () => {
        setStoreItem(undefined);
        hide();
    };

    return [showModal, hide, isShowing];
};

export default useProductModal;
