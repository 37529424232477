import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Wrapper = styled.div`
    position: relative;
`;

export const FormSelect = styled.div<{ inline?: boolean; isLight?: boolean }>`
    height: ${({ theme }) => theme.form.height}rem;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.greys[50]};
    border-radius: ${({ theme }) => theme.form.height / 2}rem;
    background-color: ${({ theme }) => theme.colors.white};

    ${({ inline, theme }) =>
        inline &&
        css`
            position: absolute;
            background-color: ${theme.colors.background};
            height: 2.5rem;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            border-radius: ${theme.borderRadius.xl}px;
            padding: 0 12px;
            font-size: 0.8rem;
            font-weight: 600;
            .icon-wrapper {
                margin-right: -5px;
                margin-left: 0;
                transform-origin: center center;
                transform: scale(0.75);
            }
        `}

    ${({ isLight, theme }) =>
        isLight &&
        css`
            width: auto;
            height: auto;
            display: inline-flex;
            padding: 0;
            padding-bottom: .25rem;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid ${theme.colors.disabled};
        `}
`;

export const Value = styled.span`
    white-space: nowrap;
    font-size: ${({ theme }) => theme.form.fontSize}rem;
`;

