// Bacause Object.keys returns a String[], we can't use a generic here. Making params a Object doesn't work either
export function buildUriWithParams(uri: string, params?: { [key: string]: any }) {
    if (!params) {
        return uri;
    }
    const args = Object.keys(params)
        .map((k) => `${k}=${params[k]}`)
        .reduce((total, current) => {
            if (total) {
                return `${total}&${current}`;
            }

            return current;
        });

    const uriWithParams = args.length > 0
        ? `${uri}?${args}`
        : uri;

    return uriWithParams;
}
