import React, { FC } from 'react';
import { readableColor } from 'polished';
import brands, { Brand } from 'static/theme/brand';
import { Wrapper } from './styles';
import { StartIcon } from './styles';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

export interface DragButtonProps {
    brand?: keyof Brand;
    className?: string;
    loading?: boolean;
    rounded?: boolean;
    size?: 'md' | 'lg';
    startIcon: string;
    draggable?: DraggableProvidedDragHandleProps;
    iconColor?: string;
    tabIndex?: number;
    title?: string;
    noFocus?: boolean;
    // Variants.
    disabled?: boolean;
    white?: boolean;
}

const DragButton: FC<DragButtonProps> = ({ loading, disabled, children, brand, startIcon, draggable, iconColor, title, ...props }) => {

    // Render.
    const color = brands[brand || 'blue'];

    return (
        <Wrapper {...draggable}>
            <StartIcon name={startIcon} color={iconColor ?? readableColor(color)} size={1} noMargin={!children} />
            {children || title}
        </Wrapper>
    );
};

export default DragButton;
